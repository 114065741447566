import { isString } from 'lodash/lang'

const roundDown = (value) => {
  return Math.round(value * 100) / 100
}

const formatNumber = (value) => {
  let result = value

  if (isString(value)) {
    result = result.trim()
    result = result.replace(',', '.')
  }

  result = Number(result)

  if (isNaN(result)) {
    return 0
  }

  return result
}

export { roundDown, formatNumber }
