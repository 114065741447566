import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useInterval from 'react-use/lib/useInterval'
import {
  BLOCKCHAIN_BSC,
  BLOCKCHAIN_ETH,
  BLOCKCHAIN_TRX,
  INVOICE_STATUS_NEW,
  PAYMENT_SYSTEMS_CRYPTO_GATEWAY,
} from 'shared/constants'

import useUserStore from 'shared/stores/user'

import { getBlockchainLabel } from 'shared/utils/billing'
import { durationShift, format } from 'shared/utils/counter'
import { asMoney } from 'shared/utils/money'
import { isGuest } from 'shared/utils/user'

import { cancelInvoice, checkInvoicePayment, getInvoice } from 'shared/api/finance'

import QrModal from '../components/QrModal'
import useStepsStore from '../stores/steps'
import { getStepData } from '../utils/steps'
import Order from './Order'

const SCANS = {
  [BLOCKCHAIN_TRX]: {
    url: 'https://tronscan.org/#/address',
    title: 'Перейти в TronScan',
  },
  [BLOCKCHAIN_BSC]: {
    url: 'https://bscscan.com/address',
    title: 'Перейти в BscScan',
  },
  [BLOCKCHAIN_ETH]: {
    url: 'https://etherscan.io/address',
    title: 'Перейти в EtherScan',
  },
}

const getScanLink = (blockchain, address) => {
  return `${SCANS[blockchain].url}/${address}`
}

const Blockchain = ({ payment, onCheck, onCancel }) => {
  const COUNTER_PERIOD = 30_000
  const COUNTER_FREQUENCY = 1_000

  const { t } = useTranslation()
  const inputEl = useRef()
  const [isCopied, setCopied] = useState(false)
  const [checkCounter, setCheckCounter] = useState(0)
  const [qrModal, toggleQrModal] = useState(false)

  const address = payment.extra?.gateway?.address
  const blockchain = payment.extra?.gateway?.blockchain

  const dur = durationShift(checkCounter)
  const checking = checkCounter > 0

  useEffect(() => {
    if (isCopied) {
      setTimeout(() => setCopied(false), 3000)
    } else {
      clearTimeout()
    }
  }, [isCopied])

  useInterval(
    () => {
      setCheckCounter(checkCounter - COUNTER_FREQUENCY)
    },
    checkCounter > 0 ? COUNTER_FREQUENCY : null,
  )

  const copyCodeToClipboard = (ev) => {
    ev.preventDefault()
    const el = inputEl.current

    el.select()
    document.execCommand('copy')
    setCopied(true)
  }

  const handleCancel = (ev) => {
    ev.preventDefault()
    onCancel && onCancel()
  }

  const handleCheck = (ev) => {
    ev.preventDefault()

    if (checking) {
      return
    }

    onCheck && onCheck()
    setCheckCounter(COUNTER_PERIOD)
  }

  const handelOpenQrModal = (ev) => {
    ev.preventDefault()
    toggleQrModal(true)
  }

  return (
    <>
      <div className="buy-token--form">
        <div className="form-group">
          <label htmlFor="" className="control-label">
            {t('Кошелек для оплаты')}
          </label>

          <div className="form-group-row form-group-row_tron">
            <div className="form-group-row--input">
              <div className="form-field">
                <input type="text" className="form-control" value={address} readOnly={true} ref={inputEl} />
                <div className="form-field--option">
                  <button className={`btn-copy copy-btn ${isCopied ? 'copied' : ''}`} onClick={copyCodeToClipboard}>
                    <svg>
                      <use xlinkHref="#copy" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div className="form-group-row--button">
              <button className="btn-qr btn-modal" onClick={handelOpenQrModal}>
                <svg>
                  <use xlinkHref="#qr" />
                </svg>
              </button>

              <a href={getScanLink(blockchain, address)} className="arrow-link" target="_blank">
                <span>{t(SCANS[blockchain].title)}</span>
                <svg>
                  <use xlinkHref="#small-arrow" />
                </svg>
              </a>
            </div>
          </div>
        </div>

        <div className="buy-token--form-buttons">
          <a href="#" className="btn btn-primary" onClick={handleCheck} disabled={checking}>
            {!checking ? t('Я ОПЛАТИЛ') : format(dur.seconds)}
          </a>
          <a href="#" className="btn" title={t('ОТМЕНА')} onClick={handleCancel}>
            {t('ОТМЕНА')}
          </a>
        </div>
      </div>

      <div className="buy-token--info">
        <div className="buy-token-info">
          <div className="buy-token-info--title">{t('Внимание:')}</div>

          <div className="buy-token-info--content">
            <p>{t('Для осуществления перевода Вам необходимо сделать следующие действия:')}</p>
            <ol>
              <li>{t('Зайдите в свой кошелек {{name}}', { name: getBlockchainLabel(blockchain) })}</li>
              <li>
                <div>
                  {t('Переведите на адрес:')} <b>{address}</b>
                </div>
                <div>
                  {t('Сумму:')} <b>{asMoney(payment.amount.value, payment.amount.currency)}</b>
                </div>
              </li>
              <li>{t('Подождите подтверждения 10 транзакций сетью.')}</li>
            </ol>
          </div>
          <div className="buy-token-info--content">
            <div className="buy-token-info--content-title">{t('ВАЖНО!')}</div>

            <ul>
              <li>
                {t(
                  'Если Вы не успели отправить средства за отведенные 30 минут, предоставленный для перевода кошелек - будет удален. Пожалуйста, не совершайте транзакцию по истечении 30 минутного таймера.',
                )}
              </li>
              <li>
                {t('Мы не принимаем средства со смарт-контрактов! Отправка средств происходит только в ручном режиме.')}
              </li>
              <li>
                {t(
                  'За переводы средств выполненные с помощью смарт-контракта, ошибочные переводы на другие блокчейны и прочие ошибки, платформа не несет ответственности!',
                )}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <QrModal value={address} isOpen={qrModal} onClose={() => toggleQrModal(false)} />
    </>
  )
}

const Waiting = ({ token }) => {
  const { t } = useTranslation()
  const COUNTER_PERIOD = 1_800_000
  const COUNTER_FREQUENCY = 1_000

  const user = useUserStore()
  const { steps, updateStep, setActiveStep, resetSteps } = useStepsStore()
  const [counter, setCounter] = useState(COUNTER_PERIOD)
  const [needUpdate, setUpdate] = useState(true)

  const isUserGuest = isGuest(user)
  const authStep = getStepData(2, steps)
  const step = getStepData(3, steps)

  const mapPaymentToOrder = (payment) => {
    return {
      amount: payment.extra.amount,
      blockchain: payment.extra?.gateway?.blockchain,
      currency: payment.amount.currency,
      currencyAmount: payment.amount.value,
      blockchainReceipt: BLOCKCHAIN_BSC,
    }
  }

  useEffect(() => {
    if (isUserGuest && !authStep.user.authKey) {
      resetSteps()
    }
  }, [isUserGuest])

  useInterval(
    () => {
      setCounter(counter - COUNTER_FREQUENCY)
    },
    counter > 0 ? COUNTER_FREQUENCY : null,
  )

  useInterval(
    () => {
      getInvoice(step.id, authStep.user.authKey)
        .then((data) => {
          updateStep(3, data)

          const isStatusNew = data.status === INVOICE_STATUS_NEW

          if (!isStatusNew) {
            setUpdate(false)
            setActiveStep(4)
          }
        })
        .catch((err) => console.log(err))
    },
    needUpdate ? 5_000 : null,
  )

  const handleCancel = () => {
    cancelInvoice(step.id, authStep.user.authKey)
      .then(() => resetSteps())
      .catch((err) => console.log(err))
  }

  const handleCheck = () => {
    checkInvoicePayment(step.id, authStep.user.authKey).catch((err) => console.log(err))
  }

  const dur = durationShift(counter)

  // const isPaymentInternal = step.paymentSystemId === PAYMENT_SYSTEMS_INTERNAL
  const isPaymentCryptoGateway = step.paymentSystemId === PAYMENT_SYSTEMS_CRYPTO_GATEWAY

  return (
    <>
      <div className="buy-token--info">
        <div className="auth auth_inline">
          <div className="auth-content">
            <div>
              <div>{t('Ожидание подтверждения оплаты...')}</div>
            </div>
          </div>
          <div className="auth-count">
            <div className="auth-counter">
              <svg viewBox="0 0 160 160" style={{ '--auth-count': '60s' }}>
                <circle cx="50%" cy="50%" r="75" className="circle2" />
                <circle cx="50%" cy="50%" r="75" className="circle1" />
              </svg>
              <div className="auth-counter--value">{`${format(dur.minutes)}:${format(dur.seconds)}`}</div>
            </div>
          </div>
        </div>
      </div>

      <Order token={token} {...mapPaymentToOrder(step)} />

      {isPaymentCryptoGateway && <Blockchain payment={step} onCheck={handleCheck} onCancel={handleCancel} />}
    </>
  )
}

export default Waiting
