import React from 'react'

import AboutSection from './components/AboutSection'
import IntroSection from './components/IntroSection'
import ObjetDetailsSection from './components/ObjetDetailsSection'

const TokenObject = () => {
  return (
    <>
      <IntroSection />
      <ObjetDetailsSection />
      <AboutSection />
    </>
  )
}

export default TokenObject
