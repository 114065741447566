import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import useBreadcrumbs from 'use-react-router-breadcrumbs'

const Breadcrumbs = ({ currentCrumbs = [] }) => {
  const { t } = useTranslation()
  const routes = [
    { path: '/', breadcrumb: t('Главная') },
    { path: '/auth', breadcrumb: t('Авторизация') },
    { path: '/auth/preregister', breadcrumb: t('Настройка профиля') },
    { path: '/auth/waiting', breadcrumb: null },
    { path: '/auth/fail', breadcrumb: null },
    { path: '/auth/refresh', breadcrumb: null },
    { path: '/news', breadcrumb: t('Новости'), disableClick: true },
    ...currentCrumbs,
  ]

  const breadcrumbs = useBreadcrumbs(routes)

  return (
    <div className="breadcrumbs">
      <ul className="breadcrumbs-list">
        {breadcrumbs.map(({ breadcrumb, match }, index, { length }) => (
          <li className="breadcrumbs-list--item" key={index}>
            {index !== length - 1 && !match.route.disableClick ? (
              <Link className={'breadcrumbs-list--link'} to={match.pathname}>
                {breadcrumb}
              </Link>
            ) : (
              breadcrumb
            )}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Breadcrumbs
