import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Element } from 'react-scroll'
import { useMount } from 'react-use'
import * as yup from 'yup'

import useFormWrapper from 'shared/hooks/useFormWrapper'

import useModalsStore from 'shared/stores/modals'

import { sendMessage } from 'shared/api/contact'

import Button from 'shared/components/Button'
import FormField from 'shared/components/forms/FormField'
import Input from 'shared/components/forms/Input'

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .min(2, <Trans i18nKey={'Минимальное количество {{count}} символа'} values={{ count: 2 }} />)
    .trim()
    .required(<Trans i18nKey={'Обязательное поле'} />),
  email: yup
    .string()
    .trim()
    .email(<Trans i18nKey={'Неправильный формат'} />)
    .required(<Trans i18nKey={'Обязательное поле'} />),
  theme: yup
    .string()
    .min(5, <Trans i18nKey={'Минимальное количество {{count}} символов'} values={{ count: 5 }} />)
    .trim()
    .required(<Trans i18nKey={'Обязательное поле'} />),
  message: yup
    .string()
    .min(10, <Trans i18nKey={'Минимальное количество {{count}} символов'} values={{ count: 10 }} />)
    .trim()
    .required(<Trans i18nKey={'Обязательное поле'} />),
})

const ContactSection = () => {
  const { t } = useTranslation()
  const modals = useModalsStore()
  const [loading, setLoading] = useState(false)
  const { form, getFieldError, setFormErrors } = useFormWrapper(validationSchema, {
    mode: 'all',
    reValidateMode: 'all',
    shouldUnregister: false,
  })

  useMount(() => form.watch())

  const handleSubmit = () => {
    if (!form.formState.isValid) return false

    const values = form.getValues()

    setLoading(true)
    sendMessage(values)
      .then((res) => {
        modals.show('info', {
          title: t('Отправлено!'),
          content: t('Ваше сообщение отправлено, мы свяжемся с вами настолько быстро, насколько это возможно'),
        })
        form.reset({ name: '', email: '', theme: '', message: '' })
      })
      .catch((e) => {
        if (e.errors) {
          setFormErrors(e.errors)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Element name={'contact-section'}>
      <section className="section section-contactus">
        <div className="section-container">
          <div className="section-content">
            <div className="contactus">
              <div className="contactus--header">
                <div className="contactus--title">{t('Свяжитесь с нами')}</div>
              </div>

              <div className="contactus--content">
                <div className="contactus--description">
                  {t('Заполните форму и мы ответим настолько быстро, насколько это возможно')}
                </div>
              </div>

              <div className="contactus--form">
                <div className="contactus-form odd-form">
                  <div className="contactus-form--row">
                    <div className="contactus-form--col">
                      <FormField error={getFieldError('name')}>
                        <Input label={t('Ваше имя')} register={form.register} name={'name'} />
                      </FormField>
                    </div>
                    <div className="contactus-form--col">
                      <FormField error={getFieldError('email')}>
                        <Input label={'Еmail'} register={form.register} name={'email'} />
                      </FormField>
                    </div>
                    <div className="contactus-form--col contactus-form--col_2x">
                      <FormField error={getFieldError('theme')}>
                        <Input label={t('Тема вопроса')} register={form.register} name={'theme'} />
                      </FormField>
                    </div>
                  </div>

                  <div className="form-group">
                    <FormField error={getFieldError('message')}>
                      <Input
                        label={t('Расскажите подробнее о своем вопросе')}
                        type={'textarea'}
                        register={form.register}
                        name={'message'}
                      />
                    </FormField>
                  </div>

                  <div className="contactus-form--buttons">
                    <Button
                      onClick={handleSubmit}
                      variant={'primary'}
                      disabled={!form.formState.isValid}
                      loading={loading}
                    >
                      <span>{t('Отправить')}</span>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Element>
  )
}

export default ContactSection
