import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMount } from 'react-use'
import * as yup from 'yup'

import useFormWrapper from 'shared/hooks/useFormWrapper'

import { purchase } from 'shared/api/tokens/purchase'

import Radio from 'shared/components/forms/Radio'

import useStepsStore, { AUTH_TYPE_EMAIL, AUTH_TYPE_TELEGRAM } from '../stores/steps'
import { getStepData } from '../utils/steps'
import EmailAuth from './EmailAuth'
import Order from './Order'
import TelegramAuth from './TelegramAuth'

const validationSchema = yup.object().shape({
  type: yup.mixed().oneOf([AUTH_TYPE_TELEGRAM, AUTH_TYPE_EMAIL]),
})

const Auth = ({ token }) => {
  const { t } = useTranslation()
  const { steps, updateStep, setActiveStep, resetSteps } = useStepsStore()
  const paymentStep = getStepData(1, steps)
  const authStep = getStepData(2, steps)
  const [loading, setLoading] = useState(false)

  const { form } = useFormWrapper(validationSchema, {
    mode: 'all',
    defaultValues: {
      type: authStep.type || AUTH_TYPE_TELEGRAM,
    },
  })

  useMount(() => form.watch())

  const { type } = form.getValues()

  useEffect(() => {
    updateStep(2, { type })
  }, [type])

  const handleCancelPayment = () => {
    resetSteps()
  }

  const handleCreatePayment = () => {
    if (loading) {
      return
    }

    const withAuth = authStep.type === AUTH_TYPE_TELEGRAM
    const payload = paymentStep

    if (!withAuth) {
      payload.email = authStep.user.email
    }

    setLoading(true)
    purchase(token, payload, withAuth)
      .then((payment) => setActiveStep(3, payment))
      .catch((err) => {
        if (err.errors) {
          setActiveStep(1, { errors: err.errors })
        }
      })
      .finally(() => setLoading(false))
  }

  return (
    <>
      <Order token={token} {...paymentStep} />

      <div className="buy-token--auth">
        <div className="buy-token-auth">
          <div className="buy-token-auth--method" style={{ display: 'none' }}>
            <ul className="options-list">
              <li className="options-list--item">
                <Radio
                  name={'type'}
                  label={t('Войти через Telegram')}
                  id={`type_telegram`}
                  value={AUTH_TYPE_TELEGRAM}
                  register={form.register}
                />
              </li>
              <li className="options-list--item">
                <Radio
                  name={'type'}
                  label={t('Пропустить')}
                  id={`type_email`}
                  value={AUTH_TYPE_EMAIL}
                  register={form.register}
                />
              </li>
            </ul>
          </div>

          {type === AUTH_TYPE_TELEGRAM && (
            <TelegramAuth
              token={token}
              paymentLoading={loading}
              onCreatePayment={handleCreatePayment}
              onCancelPayment={handleCancelPayment}
            />
          )}
          {type === AUTH_TYPE_EMAIL && (
            <EmailAuth
              token={token}
              paymentLoading={loading}
              onCreatePayment={handleCreatePayment}
              onCancelPayment={handleCancelPayment}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default Auth
