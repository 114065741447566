import create from 'zustand'

import { getCurrencyFees } from 'shared/api/finance'

const useStore = create((set) => ({
  data: {},
  update: (data) => set(() => ({ data })),
  receiveFees: () =>
    set(async (state) => {
      try {
        const res = await getCurrencyFees()
        const buffData = {}

        res.forEach((fee) => {
          buffData[`${fee.blockchain}_${fee.token}`] = fee.amount
        })

        return state.update(buffData)
      } catch (err) {
        return state
      }
    }),
}))

export default useStore
