import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useMount } from 'react-use'
import useInterval from 'react-use/lib/useInterval'
import * as yup from 'yup'

import useFormWrapper from 'shared/hooks/useFormWrapper'

import useUserStore from 'shared/stores/user'

import { durationShift, format } from 'shared/utils/counter'

import { emailConfirmation, requestEmailConfirmation } from 'shared/api/tokens/purchase'

import FormField from 'shared/components/forms/FormField'
import Input from 'shared/components/forms/Input'

import useStepsStore from '../stores/steps'
import { getStepData } from '../utils/steps'
import ResultAuth from './ResultAuth'
import Sponsor from './Sponsor'

const sendCodeSchema = yup.object().shape({
  email: yup
    .string()
    .email(<Trans i18nKey={'Неправильный формат'} />)
    .required(<Trans i18nKey={'Обязательное поле'} />),
})

const SendCodeForm = ({ token, onSuccess }) => {
  const { t } = useTranslation()
  const COUNTER_PERIOD = 30_000
  const COUNTER_FREQUENCY = 1_000

  const [loading, setLoading] = useState(false)
  const [counter, setCounter] = useState(0)

  const { form, getFieldError, setFormErrors } = useFormWrapper(sendCodeSchema, {
    mode: 'onChange',
  })

  const runCounter = counter > 0
  const disabled = (!form.formState.isValid && !loading) || runCounter
  const dur = durationShift(counter)

  useMount(() => form.watch())

  useInterval(
    () => {
      if (counter > 0) {
        setCounter(counter - COUNTER_FREQUENCY)
      }
    },
    runCounter ? COUNTER_FREQUENCY : null,
  )

  const handleSubmit = (values) => {
    if (!form.formState.isValid || loading) {
      return
    }

    setLoading(true)
    requestEmailConfirmation(token, values)
      .then(() => {
        setCounter(COUNTER_PERIOD)
        onSuccess && onSuccess(values.email)
      })
      .catch((err) => {
        if (err.errors) {
          setFormErrors(err.errors)
        }
      })
      .finally(() => setLoading(false))
  }

  return (
    <form onSubmit={form.handleSubmit(handleSubmit)}>
      <FormField error={getFieldError('email')}>
        <label htmlFor="email" className="control-label">
          Email
        </label>

        <div className="form-group-row">
          <div className="form-group-row--input">
            <Input name={'email'} disabled={runCounter} register={form.register} />
            <div className="help-message">{t('На email будет отправлен код')}</div>
          </div>

          <div className="form-group-row--button">
            <button className={`btn btn-light ${loading ? 'loading' : ''}`} disabled={disabled}>
              <span>{t('Отправить код')}</span>
            </button>
            {runCounter && (
              <div className="help-message">{t('Повторить через: {{count}} сек', { count: format(dur.seconds) })}</div>
            )}
          </div>
        </div>
      </FormField>
    </form>
  )
}

const confirmCodeSchema = yup.object().shape({
  code: yup
    .string()
    .required(<Trans i18nKey={'Обязательное поле'} />)
    .trim(),
})

const ConfirmCodeForm = ({ token, email, refKey = null, onSuccess }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)

  const { form, getFieldError, setFormErrors } = useFormWrapper(confirmCodeSchema, {
    mode: 'onChange',
  })

  useMount(() => form.watch())

  const handleSubmit = (values) => {
    if (!form.formState.isValid || loading) {
      return
    }

    setLoading(true)
    emailConfirmation(token, { ...values, email, refKey })
      .then(({ user }) => {
        if (user) {
          onSuccess && onSuccess(user)
        } else {
          setFormErrors({ code: ['Подтверждение не удалось'] })
        }
      })
      .catch((err) => {
        if (err.errors) {
          setFormErrors(err.errors)
        }
      })
      .finally(() => setLoading(false))
  }

  const disabled = !form.formState.isValid && !loading

  return (
    <form onSubmit={form.handleSubmit(handleSubmit)}>
      <FormField error={getFieldError('code')}>
        <label htmlFor="code" className="control-label">
          {t('Код из E-mail')}
        </label>

        <div className="form-group-row">
          <div className="form-group-row--input">
            <Input name={'code'} disabled={loading} register={form.register} />
            <div className="help-message">{t('Введите код из письма')}</div>
          </div>

          <div className="form-group-row--button">
            <button className={`btn btn-light ${loading ? 'loading' : ''}`} disabled={disabled}>
              <span>{t('Подтвердить')}</span>
            </button>
          </div>
        </div>
      </FormField>
    </form>
  )
}

const EmailAuth = ({ token, paymentLoading = false, onCreatePayment, onCancelPayment }) => {
  const user = useUserStore()
  const { steps, updateStep } = useStepsStore()
  const [sentCode, setSentCode] = useState(false)

  const stepData = getStepData(2, steps)
  const isAuth = stepData.user?.login !== null
  const email = stepData.user?.email

  const handleShowConfirmation = (email) => {
    setSentCode(true)
    updateStep(2, { user: { ...stepData.user, email } })
  }

  const handleConfirmation = (user) => {
    updateStep(2, { user })
  }

  return (
    <>
      {!isAuth && (
        <div className="buy-token-auth--sponsor">
          <Sponsor user={user} />
        </div>
      )}

      <div className="buy-token-auth--form">
        <div className="email-verification-form">
          {isAuth ? (
            <ResultAuth
              user={stepData.user}
              loading={paymentLoading}
              showSponsor={true}
              onCreate={onCreatePayment}
              onCancel={onCancelPayment}
            />
          ) : (
            <>
              <SendCodeForm token={token} onSuccess={handleShowConfirmation} />

              {sentCode && (
                <ConfirmCodeForm token={token} email={email} refKey={user.ref} onSuccess={handleConfirmation} />
              )}
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default EmailAuth
