import { login as loginDefault } from 'shared/api/auth'

import useLogin from './useLogin'

const useLoginConsole = () => {
  const login = useLogin()

  const handleLogin = (email, password) => {
    loginDefault({ email, password, fingerprint: email })
      .then((data) => {
        if (data?.accessToken) {
          login(data.accessToken, data.refreshToken)
        }
      })
      .catch((err) => console.log(err))
  }

  return {
    login: handleLogin,
  }
}

export default useLoginConsole
