import React from 'react'
import { useNavigate } from 'react-router'
import { useMount } from 'react-use'

import Loader from 'components/Loader'

import useUserStore from 'shared/stores/user'

const Logout = () => {
  const user = useUserStore()
  const navigate = useNavigate()

  useMount(() => {
    setTimeout(() => {
      user.logout()
      navigate('/auth')
    }, 600)
  })

  return <Loader />
}

export default Logout
