import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useClickAway } from 'react-use'

import { getAvatar, getFullName } from 'shared/utils/user'

import { CABINET_URL } from '../shared/constants'

const UserMenu = ({ user }) => {
  const { t } = useTranslation()

  const [open, toggleOpen] = useState(false)
  const el = useRef()

  const handleOpenList = (ev) => {
    ev.preventDefault()
    toggleOpen(true)
  }

  const handleLogout = (ev) => {
    ev.preventDefault()
    user.logout()
  }

  useClickAway(el, () => toggleOpen(false))

  return (
    <div className={`usermenu ${open ? 'is-active' : ''}`} ref={el}>
      <a href="#" className="usermenu--button" onClick={handleOpenList}>
        <div className="usercard">
          <div className="usercard--image">
            <img src={getAvatar(user)} alt="" />
          </div>
          <div className="usercard--content">
            <div className="usercard--name">{getFullName(user)}</div>
            <div className="usercard--nick">{user.login}</div>
          </div>
        </div>
      </a>

      <ul className="usermenu-menu">
        <li className="usermenu-menu--item">
          <a href={`${CABINET_URL}/`} className="usermenu-menu--link">
            {t('Личный кабинет')}
          </a>
        </li>
        <li className="usermenu-menu--item">
          <a href="#" className="usermenu-menu--link" onClick={handleLogout}>
            {t('Выйти')}
          </a>
        </li>
      </ul>
    </div>
  )
}

export default UserMenu
