import React from 'react'
import { useTranslation } from 'react-i18next'
import { useEffectOnce } from 'react-use'

import { getReferrer } from 'shared/utils/user'

import { getReferrer as fetchReferrer } from 'shared/api/auth'

const Sponsor = ({ user }) => {
  const { t } = useTranslation()
  const referrer = getReferrer(user)

  useEffectOnce(() => {
    if (user.ref && referrer === null) {
      fetchReferrer(user.ref)
        .then((data) => user.update({ referrer: data }))
        .catch((err) => console.log(err))
    }
  })

  const sponsorName = referrer ? (referrer.login === 'admin' ? 'Administrator' : referrer.login) : 'Administrator'

  return (
    <div className={`form-group ${referrer ? '' : 'has-error'}`}>
      <label htmlFor="sponsor" className="control-label">
        {t('Ваш личный Агент:')}
      </label>
      <input type="text" className="form-control" id="sponsor" value={sponsorName} readOnly={true} />
      {!referrer && (
        <div className="feedback-message">
          {t(
            'Вы регистрируетесь без использования ссылки личного Агента. Вы будете находиться в офисе Администрации.\n' +
              'Если у Вас есть личный Агент, то перезайдите на сайт по партнерской ссылке Вашего Агента.',
          )}
        </div>
      )}
    </div>
  )
}

export default Sponsor
