import { WALLET_TYPE_USCR } from '../constants'

const getBalance = (user, type = WALLET_TYPE_USCR) => {
  return user.balances[type] !== undefined ? user.balances[type].balance : 0
}

const getFullName = (user) => {
  return [user.profile.firstName, user.profile.lastName].filter(Boolean).join(' ')
}

const getDefaultAvatar = () => {
  return '/images/default-avatar.svg'
}

const getAvatar = (user) => {
  return user.profile.avatar || getDefaultAvatar()
}

const getReferrer = (user) => {
  return user.referrer.login ? user.referrer : null
}

const isGuest = (user) => {
  return user.accessToken === null
}

export { getBalance, getFullName, getAvatar, getDefaultAvatar, getReferrer, isGuest }
