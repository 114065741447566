import React from 'react'

const Input = ({
  register = () => {},
  name,
  type = 'text',
  label = null,
  placeholder,
  disabled = false,
  readOnly = false,
  options = {
    cols: 30,
    rows: 10,
  },
  ...rest
}) => {
  return (
    <>
      {label && (
        <label htmlFor={name} className="control-label">
          {label}
        </label>
      )}
      {type === 'textarea' ? (
        <textarea {...register(name)} {...options} id={name} placeholder={placeholder} className="form-control" />
      ) : (
        <input
          {...register(name)}
          {...rest}
          type={type}
          className="form-control"
          id={name}
          disabled={disabled}
          placeholder={placeholder}
          readOnly={readOnly}
        />
      )}
    </>
  )
}

export default Input
