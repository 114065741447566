import React from 'react'
import { useTranslation } from 'react-i18next'
import { Element } from 'react-scroll'

const FeaturesSection = () => {
  const { t } = useTranslation()

  return (
    <Element className="section section-features" name={'adv-section'}>
      <div className="section-container">
        <div className="section-content">
          <div className="home-features">
            <div className="home-features--title">{t('8 причин присоединиться к CoinRealtor')}</div>

            <div className="home-features--content">
              <ul className="home-features-list">
                <li className="home-features-list--item">
                  <div className="home-features-list--item-icon">
                    <img src="images/feature-1.svg" alt="" />
                  </div>
                  <div className="home-features-list--item-content">
                    <div className="home-features-list--item-title">{t('Минимальный порог входа')}</div>
                    <div className="home-features-list--item-description">
                      {t('Покупка токенов за любую, даже самую незначительную сумму')}
                    </div>
                  </div>
                </li>
                <li className="home-features-list--item">
                  <div className="home-features-list--item-icon">
                    <img src="./images/feature-2.svg" alt="" />
                  </div>
                  <div className="home-features-list--item-content">
                    <div className="home-features-list--item-title">{t('Диверсификация активов')}</div>
                    <div className="home-features-list--item-description">
                      {t('Диверсификация активов в токены, обеспеченные разной недвижимостью')}
                    </div>
                  </div>
                </li>
                <li className="home-features-list--item">
                  <div className="home-features-list--item-icon">
                    <img src="./images/feature-3.svg" alt="" />
                  </div>
                  <div className="home-features-list--item-content">
                    <div className="home-features-list--item-title">{t('Оплата популярной криптовалютой')}</div>
                    <div className="home-features-list--item-description">
                      {t('Инвестирование за счет любой криптовалюты')}
                    </div>
                  </div>
                </li>
                <li className="home-features-list--item">
                  <div className="home-features-list--item-icon">
                    <img src="./images/feature-4.svg" alt="" />
                  </div>
                  <div className="home-features-list--item-content">
                    <div className="home-features-list--item-title">{t('Минимальные издержки')}</div>
                    <div className="home-features-list--item-description">
                      {t('Оплата минималных транзакционных издержек')}
                    </div>
                  </div>
                </li>
                <li className="home-features-list--item">
                  <div className="home-features-list--item-icon">
                    <img src="./images/feature-5.svg" alt="" />
                  </div>
                  <div className="home-features-list--item-content">
                    <div className="home-features-list--item-title">{t('Безопасность вложений')}</div>
                    <div className="home-features-list--item-description">
                      {t('Защита всех вложений за счет передовых технологий')}
                    </div>
                  </div>
                </li>
                <li className="home-features-list--item">
                  <div className="home-features-list--item-icon">
                    <img src="./images/feature-6.svg" alt="" />
                  </div>
                  <div className="home-features-list--item-content">
                    <div className="home-features-list--item-title">{t('Прозрачность операций')}</div>
                    <div className="home-features-list--item-description">
                      {t(
                        'Фиксирование каждого экземпляра транзакции в блокчейне без дорогостоящих и трудоемких документов',
                      )}
                    </div>
                  </div>
                </li>
                <li className="home-features-list--item">
                  <div className="home-features-list--item-icon">
                    <img src="./images/feature-7.svg" alt="" />
                  </div>
                  <div className="home-features-list--item-content">
                    <div className="home-features-list--item-title">{t('Абсолютная анонимность')}</div>
                    <div className="home-features-list--item-description">
                      {t('Передача токенов через блокчейн - абсолютно анонимно и без бумажной волокиты')}
                    </div>
                  </div>
                </li>
                <li className="home-features-list--item">
                  <div className="home-features-list--item-icon">
                    <img src="./images/feature-8.svg" alt="" />
                  </div>
                  <div className="home-features-list--item-content">
                    <div className="home-features-list--item-title">{t('Высокая ликвидность сделок')}</div>
                    <div className="home-features-list--item-description">
                      {t('Быстрая и эффективная купля-продажа токенов с помощью децентрализованных ресурсов')}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Element>
  )
}

export default FeaturesSection
