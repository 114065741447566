import React, { useState } from 'react'
import { Route, Routes } from 'react-router'

import Breadcrumbs from 'components/Breadcrumbs'

import Fail from './components/Fail'
import Login from './components/Login'
import Logout from './components/Logout'
import Preregister from './components/Preregister'
import Refresh from './components/Refresh'
import Waiting from './components/Waiting'

const Auth = () => {
  const [errors, setErrors] = useState()

  const handleLoginError = (errors) => {
    setErrors(errors)
  }

  return (
    <div className="section section-auth">
      <div className="section-container">
        <div className="section-content">
          <div className="section-top">
            <Breadcrumbs />
          </div>

          <div className="section-main">
            <Routes>
              <Route index element={<Login onError={handleLoginError} />} />
              <Route path={'waiting'} element={<Waiting onError={handleLoginError} />} />
              <Route path={'preregister'} element={<Preregister />} />
              <Route path={'fail'} element={<Fail errors={errors} />} />
              <Route path={'refresh'} element={<Refresh />} />
              <Route path={'logout'} element={<Logout />} />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Auth
