import React from 'react'
import { useTranslation } from 'react-i18next'

import Breadcrumbs from 'components/Breadcrumbs'

import TokenCard from 'shared/components/TokenCard'

const ObjectDetailsSection = () => {
  const { t } = useTranslation()

  return (
    <div className="section section-object">
      <div className="section-container">
        <div className="section-content">
          <div className="section-supertop">
            <div className="object-short-description">
              <div className="object-short-description--header">
                <div className="object-short-description--title">Grove Creek Beach Dubai</div>
              </div>

              <div className="object-short-description--content">
                {t(
                  'Покупай токены DCGT, которые обеспечены квадратными футами в жилом комплексе GROVE в районе Creek Beach Dubai. Комплекс из 5 элегантных зданий с собственным бассейном, выходящий на 700-метровой пляж с развлекательными, спортивными и прогулочными зонами. Район расположен в самом сердце Dubai Creek с самым высоким строящимся зданием в мире — Dubai Creek Tower, плановая высота которого 1345 метров.',
                )}
              </div>
            </div>
          </div>

          <div className="section-top">
            <Breadcrumbs
              currentCrumbs={[
                { path: 'objects', breadcrumb: null },
                { path: 'objects/:slug', breadcrumb: 'Grove Creek Beach Dubai' },
              ]}
            />
          </div>

          <div className="section-main">
            <div className="objects">
              <TokenCard isObject />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ObjectDetailsSection
