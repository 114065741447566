import React from 'react'

export default function () {
  return (
    <div id="preloader" className="preloader">
      <div className="preloader-logo">
        <div className="header-logo--link">CoinRealtor</div>
      </div>
      <div className="preloader-animation">
        <div className="spinner" />
      </div>
    </div>
  )
}
