import { useState } from 'react'
import { useClickAway } from 'react-use'

const useDropdown = (selectEl) => {
  const [isActive, setActive] = useState(false)
  const activeClassName = isActive ? 'is-active' : ''

  useClickAway(selectEl, () => {
    if (isActive) {
      setActive(false)
    }
  })
  const toggleDropdown = () => {
    setActive(!isActive)
  }

  return {
    isActive,
    setActive,
    toggleDropdown,
    activeClassName,
  }
}

export default useDropdown
