import create from 'zustand'

const useStore = create((set) => ({
  refill: false,
  withdrawal: false,
  info: false,
  discount: false,
  props: {},
  show: (type, props) => set(() => ({ [type]: true, props })),
  hide: (type) => set(() => ({ [type]: false, props: {} })),
}))

export default useStore
