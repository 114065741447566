import { yupResolver } from '@hookform/resolvers/yup'
import has from 'lodash/has'
import some from 'lodash/some'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

const useFormWrapper = (schema, config) => {
  const { t } = useTranslation()

  const form = useForm({
    ...config,
    resolver: yupResolver(schema),
  })

  const setFormValues = (values, config = {}, trigger = false) => {
    Object.keys(values).forEach((key) => {
      form.setValue(key, values[key], config)

      if (trigger) {
        form.trigger(key)
      }
    })
  }

  const setFormErrors = (errors) => {
    Object.keys(errors).forEach((key) => {
      const errMessage = errors[key][0]

      form.setError(key, { message: t(errMessage) })
    })
  }

  const renderFields = () => {
    return Object.keys(schema.fields).map((field) => ({ name: field, errorMessage: form.errors[field].message }))
  }

  const getFieldError = (fieldKey) => form.formState.errors[fieldKey]?.message

  const getFieldValue = (fieldKey) => form.getValues()[fieldKey]

  const fieldsHaveError = (fields = []) => {
    return some(fields, (key) => has(form.formState.errors, key))
  }

  return {
    form,
    setFormValues,
    setFormErrors,
    renderFields,
    getFieldError,
    getFieldValue,
    fieldsHaveError,
  }
}

export default useFormWrapper
