import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import useUserStore from 'shared/stores/user'

import { isGuest } from 'shared/utils/user'

import { CURRENCY_DCGT } from '../constants'

const MainButton = ({ title = 'Создать аккаунт', loggedTitle = 'Личный кабинет' }) => {
  const user = useUserStore()
  const { t } = useTranslation()

  const isUserGuest = isGuest(user)

  return !isUserGuest ? (
    <Link to={`/purchase/${CURRENCY_DCGT}`} className="btn btn-primary">
      {t(loggedTitle)}
    </Link>
  ) : (
    <Link to={'/auth'} className={'btn btn-primary'}>
      {t(title)}
    </Link>
  )
}

export default MainButton
