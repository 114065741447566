import Chartist from 'chartist'
// eslint-disable-next-line
import * as Gradient from 'chartist-plugin-gradient'
// eslint-disable-next-line
import * as Tooltip from 'chartist-plugin-tooltips-updated'
import { get, max, min } from 'lodash'
import { reverse } from 'lodash/array'
import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import { Trans } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { useMount } from 'react-use'
import YouTube from 'react-youtube'
import useStepsStore from 'screens/Purchase/stores/steps'
import {
  BLOCKCHAIN_BSC,
  BLOCKCHAIN_ETH,
  BLOCKCHAIN_TRX,
  CURRENCY_BNB,
  CURRENCY_BUSD,
  CURRENCY_DAI,
  CURRENCY_ETH,
  CURRENCY_TRX,
  CURRENCY_USCR,
  CURRENCY_USCR_NAME,
  CURRENCY_USDC,
  CURRENCY_USDT,
  PURCHASE_MIN_AMOUNT,
  TYPE_AMOUNT,
  TYPE_CURRENCY_AMOUNT,
} from 'shared/constants'
import * as yup from 'yup'

import useCalculator from 'shared/hooks/useCalculator'
import useDropdown from 'shared/hooks/useDropdown'
import useFormWrapper from 'shared/hooks/useFormWrapper'
import useTokenDetail from 'shared/hooks/useTokenDetail'

import useUserStore from 'shared/stores/user'

import { composeCurrency, getCurrencyDescription, getCurrencyIcon, recomposeCurrency } from 'shared/utils/billing'
import { asNumber, asUSD } from 'shared/utils/money'
import { isGuest } from 'shared/utils/user'

import { getObjects } from 'shared/api/objects'

import Button from './Button'

const BUFF_CURRENCY = [
  {
    title: CURRENCY_USDT,
    value: composeCurrency(CURRENCY_USDT, BLOCKCHAIN_TRX),
    name: getCurrencyDescription(CURRENCY_USDT, BLOCKCHAIN_TRX),
    img: getCurrencyIcon(CURRENCY_USDT, BLOCKCHAIN_TRX),
    disabled: false,
  },
  {
    title: CURRENCY_TRX,
    value: composeCurrency(CURRENCY_TRX, BLOCKCHAIN_TRX),
    name: getCurrencyDescription(CURRENCY_TRX, BLOCKCHAIN_TRX),
    img: getCurrencyIcon(CURRENCY_TRX, BLOCKCHAIN_TRX),
    disabled: false,
  },

  {
    title: CURRENCY_USDT,
    value: composeCurrency(CURRENCY_USDT, BLOCKCHAIN_BSC),
    name: getCurrencyDescription(CURRENCY_USDT, BLOCKCHAIN_BSC),
    img: getCurrencyIcon(CURRENCY_USDT, BLOCKCHAIN_BSC),
    disabled: false,
  },
  {
    title: CURRENCY_BUSD,
    value: composeCurrency(CURRENCY_BUSD, BLOCKCHAIN_BSC),
    name: getCurrencyDescription(CURRENCY_BUSD, BLOCKCHAIN_BSC),
    img: getCurrencyIcon(CURRENCY_BUSD, BLOCKCHAIN_BSC),
    disabled: false,
  },
  {
    title: CURRENCY_BNB,
    value: composeCurrency(CURRENCY_BNB, BLOCKCHAIN_BSC),
    name: getCurrencyDescription(CURRENCY_BNB, BLOCKCHAIN_BSC),
    img: getCurrencyIcon(CURRENCY_BNB, BLOCKCHAIN_BSC),
    disabled: false,
  },

  {
    title: CURRENCY_USDT,
    value: composeCurrency(CURRENCY_USDT, BLOCKCHAIN_ETH),
    name: getCurrencyDescription(CURRENCY_USDT, BLOCKCHAIN_ETH),
    img: getCurrencyIcon(CURRENCY_USDT, BLOCKCHAIN_ETH),
    disabled: false,
  },
  {
    title: CURRENCY_USDC,
    value: composeCurrency(CURRENCY_USDC, BLOCKCHAIN_ETH),
    name: getCurrencyDescription(CURRENCY_USDC, BLOCKCHAIN_ETH),
    img: getCurrencyIcon(CURRENCY_USDC, BLOCKCHAIN_ETH),
    disabled: false,
  },
  {
    title: CURRENCY_DAI,
    value: composeCurrency(CURRENCY_DAI, BLOCKCHAIN_ETH),
    name: getCurrencyDescription(CURRENCY_DAI, BLOCKCHAIN_ETH),
    img: getCurrencyIcon(CURRENCY_DAI, BLOCKCHAIN_ETH),
    disabled: false,
  },
  {
    title: CURRENCY_ETH,
    value: composeCurrency(CURRENCY_ETH, BLOCKCHAIN_ETH),
    name: getCurrencyDescription(CURRENCY_ETH, BLOCKCHAIN_ETH),
    img: getCurrencyIcon(CURRENCY_ETH, BLOCKCHAIN_ETH),
    disabled: false,
  },
]

const TokenObjectForm = ({ tokenDetails }) => {
  const navigate = useNavigate()
  const user = useUserStore()
  const { updateStep } = useStepsStore()
  const selectEl = useRef()
  const { toggleDropdown, activeClassName } = useDropdown(selectEl)

  const [activeAmount, setActiveAmount] = useState('')
  const [selectedCurrency, setSelectedCurrency] = useState(BUFF_CURRENCY[0])
  const [order, setOrder] = useState([TYPE_AMOUNT, TYPE_CURRENCY_AMOUNT])

  const isUserGuest = isGuest(user)

  const { form, setFormValues } = useFormWrapper(validationSchema, {
    mode: 'all',
    reValidateMode: 'all',
    defaultValue: {
      amount: 0,
      currencyAmount: 0,
    },
  })

  const { loading } = useCalculator(form, selectedCurrency.value, activeAmount)

  useMount(() => {
    form.watch()
  })

  useEffect(() => {
    if (isUserGuest) {
      setSelectedCurrency(BUFF_CURRENCY[0])
    }
  }, [isUserGuest])

  useEffect(() => {
    if (tokenDetails.rate > 0) {
      setFormValues({ amount: Math.ceil(PURCHASE_MIN_AMOUNT / tokenDetails.rate) }, {}, true)
      setActiveAmount(TYPE_AMOUNT)
    }
  }, [tokenDetails])

  const handleSelectCurrency = (ev, curr, disabled = false) => {
    ev.preventDefault()

    if (disabled) {
      return
    }

    setSelectedCurrency(curr)
    toggleDropdown()
  }

  const handleClickPurchase = () => {
    const { amount } = form.getValues()

    if (amount > 0) {
      const { currency, blockchain } = recomposeCurrency(selectedCurrency.value)

      updateStep(1, { amount, currency, blockchain })
      navigate(`/purchase/${tokenDetails.token}`)
    }
  }

  function disableMinusButton(e) {
    if (e.code === 'Minus') {
      e.preventDefault()
    }
  }

  const handleOnChange = (e, key) => {
    const value = e.target.value

    setActiveAmount(key)
    if (+value >= 0) {
      form.setValue(key, e.target.value)
    } else {
      e.preventDefault()
    }
  }

  const handleSwitch = () => {
    const newArray = [...order]

    setOrder(reverse(newArray))
  }

  const CURRENCIES = useMemo(() => {
    if (!isUserGuest) {
      return [
        ...BUFF_CURRENCY,
        {
          value: CURRENCY_USCR,
          title: CURRENCY_USCR_NAME,
          name: getCurrencyDescription(CURRENCY_USCR),
          img: getCurrencyIcon(CURRENCY_USCR),
          disabled: false,
        },
      ]
    }

    return BUFF_CURRENCY
  }, [JSON.stringify(isUserGuest)])

  const AMOUNTS = {
    currencyAmount: (
      <div className="reverse-field--item">
        <div
          className={`dropdown dropdown_input ${activeClassName} ${
            loading && activeAmount === TYPE_AMOUNT ? 'loading' : ''
          } `}
          ref={selectEl}
        >
          <button className="dropdown-button" onClick={toggleDropdown}>
            <div className="dropdown-button--content">
              <div className="currency-card currency-card_sm">
                <div className="currency-card--image">
                  <img src={selectedCurrency.img} alt="USDT" />
                </div>
                <div className="currency-card--content">
                  <div className="currency-card--title">{selectedCurrency.title}</div>
                  <div className="currency-card--subtitle">{selectedCurrency.name}</div>
                </div>
              </div>
            </div>
            <svg>
              <use xlinkHref="#caret" />
            </svg>
          </button>

          <input
            {...form.register(TYPE_CURRENCY_AMOUNT)}
            type="number"
            min="0"
            step={0.5}
            onChange={(e) => handleOnChange(e, TYPE_CURRENCY_AMOUNT)}
            onKeyDown={disableMinusButton}
            className="dropdown-control"
            autoComplete="off"
          />

          <ul className="dropdown-list">
            {CURRENCIES.map((curr) => (
              <li
                key={curr.value}
                className={`dropdown-list--item ${curr.title === selectedCurrency.title}`}
                onClick={(ev) => handleSelectCurrency(ev, curr, curr.disabled)}
              >
                <a href="#" className="dropdown-list--item-button dropdown-option" disabled={curr.disabled}>
                  <div className="currency-card">
                    <div className="currency-card--image">
                      <img src={curr.img} alt={curr.title} />
                    </div>
                    <div className="currency-card--content">
                      <div className="currency-card--title">{curr.title}</div>
                      <div className="currency-card--subtitle">{curr.name}</div>
                    </div>
                  </div>
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    ),
    amount: (
      <div className="reverse-field--item">
        <div
          className={`dropdown dropdown_input dropdown_nodrop ${
            loading && activeAmount !== TYPE_AMOUNT ? 'loading' : ''
          }`}
        >
          <button className="dropdown-button">
            <div className="dropdown-button--content">
              <div className="currency-card currency-card_sm">
                <div className="currency-card--image">
                  <img src="/uploads/coin-dcgt-v2.svg" alt="DCGT" />
                </div>
                <div className="currency-card--content">
                  <div className="currency-card--title">{tokenDetails.token}</div>
                  <div className="currency-card--subtitle">{tokenDetails.name}</div>
                </div>
              </div>
            </div>
            <svg>
              <use xlinkHref="#caret" />
            </svg>
          </button>

          <input
            {...form.register(TYPE_AMOUNT)}
            type="number"
            min="0"
            step={0.5}
            onChange={(e) => handleOnChange(e, TYPE_AMOUNT)}
            onKeyDown={disableMinusButton}
            className="dropdown-control"
            autoComplete="off"
          />
        </div>
      </div>
    ),
  }

  return (
    <>
      <div className="object--form">
        <div className="object--form-discount" style={{ display: 'none' }}>
          <Trans
            i18nKey={'{{percent}}% скидка'}
            components={{
              percent: 10,
            }}
          />
        </div>
        <div className="reverse-field">
          {order.map((amountType) => (
            <Fragment key={amountType}>{AMOUNTS[amountType]}</Fragment>
          ))}
          <div className="reverse-field--button">
            <button className="reverse-button" onClick={handleSwitch}>
              <span>Поменять местами</span>
              <svg>
                <use xlinkHref="#icon-reverse" />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div className="object--buttons">
        <Button variant={'primary'} onClick={handleClickPurchase}>
          <Trans i18nKey={'Купить'} />
        </Button>
      </div>
    </>
  )
}

const validationSchema = yup.object().shape({
  amount: yup.number(),
  currencyAmount: yup.number(),
})

const TokenCard = ({ isObject = false, token = 'DCGT' }) => {
  const { tokenDetails, tokenRates } = useTokenDetail(token)
  const [tokenObject, setTokenObject] = useState({})

  const [hideButton, setHideButton] = useState(false)
  const [hideImage, setHideImage] = useState(false)

  const currentChart = useRef()
  const nextChart = useRef()
  const videoRef = useRef()

  const totalPercent = Number((tokenDetails.data.totalSold / tokenDetails.data.total) * 100).toFixed(1)
  const roundPercent = Number((tokenDetails.data.roundSold / tokenDetails.data.round) * 100).toFixed(1)

  function createTokenChart(currentRef, data, mylow = 0, myHigh = 0, myHeight = '11rem', valueString, myClass = '') {
    if (currentRef.current) {
      new Chartist.Line(currentRef.current, data, {
        high: myHigh,
        low: mylow,
        scaleMinSpace: 10,
        showArea: true,
        areaBase: parseFloat(mylow).toFixed(2),
        showPoint: true,
        axisX: {
          showGrid: false,
          offset: 0,
          showLabel: false,
        },
        axisY: {
          showGrid: false,
          offset: 0,
          showLabel: false,
        },
        height: myHeight,
        fullWidth: true,
        chartPadding: {
          right: 0,
          left: 0,
        },
        plugins: [
          Chartist.plugins.ctAreaGradient({
            threshold: parseFloat(mylow).toFixed(2),
          }),
        ],
      })
    }
  }

  useMount(() => {
    getObjects().then((res) => {
      setTokenObject(res.data[0])
    })
  })

  useEffect(() => {
    const dataRates = tokenRates.data

    if (dataRates) {
      const data2 = {
        labels: dataRates.map((item) => item.date),
        series: [dataRates.map((item) => (item.amount > 0 ? item.amount : 0))],
      }

      const data1 = {
        labels: dataRates.filter((item, key) => key < dataRates.length - 1).map((item) => item.date),
        series: [
          dataRates
            .filter((item, key) => key < dataRates.length - 1)
            .map((item) => (item.amount > 0 ? item.amount : 0)),
        ],
      }

      const low1 = data1.series?.[0]?.[0] || 0
      const low2 = data2.series?.[0]?.[0] || 0

      const minAmount = min(data2.series?.[0])
      const maxAmount = max(data2.series?.[0])
      const diff = (maxAmount - minAmount) * 2

      const high1 = low1 + diff
      const high2 = maxAmount + diff

      createTokenChart(currentChart, data1, low1, high1, '11rem', ` ${token}`, 'green')
      createTokenChart(nextChart, data2, low2, high2, '11rem', ` ${token}`, 'green')
    }
  }, [JSON.stringify(tokenRates.data)])

  const handleShowVideo = () => {
    videoRef.current.playVideo()

    setHideButton(true)

    setTimeout(() => {
      setHideImage(true)
    }, 300)
  }

  const opts = {
    height: '315',
    width: '560',

    playerVars: {
      autoplay: 0,
      end: 30,
      controls: 0,
      rel: 0,
      modestbranding: 1,
    },
  }

  function onPlayerReady(event) {
    videoRef.current = event.target
  }

  return (
    <div className="objects--item">
      <div className={`object ${isObject ? 'object_single' : ''}`}>
        <div className="object-col object-description">
          <div className="object--media">
            {isObject ? (
              <div className="video-button">
                <div
                  className={`video-button--button video-trigger${hideButton ? ' is-hide' : ''} ${
                    hideImage ? 'hide' : ''
                  }`}
                  onClick={handleShowVideo}
                >
                  <img src="/uploads/video-preview2.jpg" alt="" />
                </div>

                <div className="video-button--video video-target">
                  <div className="embed-responsive embed-responsive-16by9">
                    <YouTube
                      videoId="0J9RAO_YGsg"
                      opts={opts}
                      onReady={onPlayerReady}
                      onEnd={() => {
                        setHideButton(false)
                        setHideImage(false)
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <img src="/uploads/object.jpg" alt="Grove в Creek Beach Dubai" />
            )}
          </div>

          <div className="object--header">
            <div className="object--title">
              <div className="currency-card currency-card_title">
                <div className="currency-card--image">
                  <img src="/uploads/coin-dcgt-v2.svg" alt={token} />
                </div>
                <div className="currency-card--content">
                  <div className="currency-card--title">{tokenDetails.data?.name}</div>
                </div>
              </div>
            </div>
          </div>

          <div className="object--content">
            <div className="object--content-row">
              {isObject ? (
                <>
                  <div className="object--content-col">
                    <div className="object-content">
                      <div className="object-content--title">
                        <Trans i18nKey={`Название токена:`} />
                      </div>
                      <div className="object-content--description">{tokenDetails.data?.name}</div>
                    </div>
                  </div>
                  <div className="object--content-col">
                    <div className="object-content">
                      <div className="object-content--title">
                        <Trans i18nKey={`Аббревиатура:`} />
                      </div>
                      <div className="object-content--description">{tokenDetails.data?.token}</div>
                    </div>
                  </div>
                  <div className="object--content-col">
                    <div className="object-content">
                      <div className="object-content--title">
                        <Trans i18nKey={`Общая эмиссия токена:`} />
                      </div>
                      <div className="object-content--description">
                        {asNumber(tokenDetails.data.emission?.[1], 0)} {token}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="object--content-col">
                    <div className="object-content">
                      <div className="object-content--title">
                        <Trans i18nKey={`Объект:`} />
                      </div>
                      <div className="object-content--description">
                        <Trans i18nKey={tokenObject.name} />
                      </div>
                    </div>
                  </div>
                  <div className="object--content-col">
                    <div className="object-content">
                      <div className="object-content--title">
                        <Trans i18nKey={`Район:`} />
                      </div>
                      <div className="object-content--description">
                        <Trans i18nKey={tokenObject.district} />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className="object-content">
              <div className="object-content--title">
                <Trans i18nKey={'Описание:'} />
              </div>
              <div className="object-content--description">
                {isObject ? (
                  <Trans i18nKey={tokenDetails.data.description} values={{ token, area: '0,001', totalArea: 8_540 }} />
                ) : (
                  <Trans i18nKey={tokenObject.description} />
                )}
              </div>
            </div>

            {isObject && (
              <>
                <div className="object-info">
                  <div className="object-info--title" style={{ marginRight: 5 }}>
                    <Trans i18nKey={'Период процедуры обратного выкупа:'} />
                  </div>
                  <div className="object-info--value">
                    <Trans
                      i18nKey={'{{date}} г'}
                      components={{
                        date: get(tokenDetails.data, 'periodRepurchase.end', new Date().getFullYear()),
                      }}
                    />
                  </div>
                </div>
              </>
            )}
          </div>

          {!isObject && (
            <div className="object--buttons">
              <Link to={'/objects/grove-creek-beach-dubai'} className="btn">
                <Trans i18nKey={'Подробнее'} />
              </Link>
            </div>
          )}
        </div>

        <div className="object-col object-data">
          <div className="object--rates">
            <div className="object-rates">
              <div className="object-rate">
                <div className="object-rate--title">
                  <Trans i18nKey={'Текущий курс'} />
                </div>
                <div className="object-rate--content">
                  <div className="object-rate--value">
                    <div className="rate">
                      <div className="rate--content">
                        <div className="rate--title">{token}/USDT</div>
                        <div className="rate--value">{asUSD(tokenDetails.data.rate)}</div>
                      </div>
                    </div>
                    <div className="object-rate--value-title" />
                    <div className="object-rate--value-value" />
                  </div>

                  <div className="object-rate--chart">
                    <div
                      id="rate-chart1"
                      className="rate-chart token-chart-sm token-chart-sm_green"
                      ref={currentChart}
                    />
                  </div>
                </div>
              </div>
              <div className="object-rate">
                <div className="object-rate--title">
                  <Trans i18nKey={'Следующий курс'} />
                </div>
                <div className="object-rate--content">
                  <div className="object-rate--value">
                    <div className="rate">
                      <div className="rate--content">
                        <div className="rate--title">{token}/USDT</div>
                        <div className="rate--value">{asUSD(tokenDetails.data.nextRate)}</div>
                      </div>
                      <div
                        className={`rate--delta rate--delta_${
                          +tokenDetails.data?.changePercent >= 0 ? 'plus' : 'minus'
                        }`}
                      >
                        {tokenDetails.data?.changePercent}%
                      </div>
                    </div>
                  </div>

                  <div className="object-rate--chart">
                    <div id="rate-chart1" className="rate-chart token-chart-sm token-chart-sm_green" ref={nextChart} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="object--progress">
            <div className="object-progress">
              <div className="object-progress--header">
                <div className="object-progress--title">
                  <Trans
                    i18nKey={'Продано всего {{token}}'}
                    components={{
                      token,
                    }}
                  />
                </div>
                <div className="object-progress--value">
                  {asNumber(tokenDetails.data.totalSold)} {token}
                </div>
              </div>
              <div className="object-progress--bars">
                <div className="progress-bar">
                  <div className="progress-bar--line">
                    <div className="progress-line">
                      <div className="progress-line--value" style={{ width: `${totalPercent}%` }} />
                    </div>
                  </div>
                  <div className="progress-bar--value">{`${asNumber(totalPercent, 1)}%`}</div>
                </div>
              </div>
            </div>
            <div className="object-progress">
              <div className="object-progress--header">
                <div className="object-progress--title">
                  <Trans i18nKey={'Продано в текущем раунде*'} />
                </div>
                <div className="object-progress--value">
                  {asNumber(tokenDetails.data.roundSold)} {token}
                </div>
              </div>
              <div className="object-progress--bars">
                <div className="progress-bar">
                  <div className="progress-bar--line">
                    <div className="progress-line">
                      <div className="progress-line--value" style={{ width: `${roundPercent}%` }} />
                    </div>
                  </div>
                  <div className="progress-bar--value">{`${asNumber(roundPercent, 1)}%`}</div>
                </div>
              </div>
              <div className="object-progress--description">
                <Trans
                  i18nKey={
                    'Продажи разделены на раунды по {{amount}} {{token}}. На данный момент каждый пройденный раунд увеличивает курс токена на {{percent}}%.'
                  }
                  components={{
                    amount: asNumber(100000),
                    token,
                    percent: 1,
                  }}
                />
              </div>
            </div>
          </div>

          <TokenObjectForm tokenDetails={tokenDetails.data} />
        </div>
      </div>
    </div>
  )
}

export default TokenCard
