import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import useInterval from 'react-use/lib/useInterval'
import useMount from 'react-use/lib/useMount'

import useUserStore from 'shared/stores/user'

import { durationShift, format } from 'shared/utils/counter'
import { isGuest } from 'shared/utils/user'

import { refresh as refreshToken } from 'shared/api/auth'

const TICKER_PERIOD = 30000
const TICKER_FREQUENCY = 1000

const Refresh = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const user = useUserStore()

  const [ticker, setTicker] = useState(TICKER_PERIOD)

  const refresh = (token) => {
    refreshToken({ token })
      .then(({ token }) => {
        user.update({ accessToken: token })
        navigate('/')
      })
      .catch(() => logout())
  }

  const logout = () => {
    user.logout()
    navigate('/auth')
  }

  useMount(() => {
    setTimeout(() => refresh(user.refreshToken), 1500)
  })

  useEffect(() => {
    if (isGuest(user)) {
      navigate('/auth')
    }
  }, [user])

  useInterval(
    () => {
      setTicker(ticker - TICKER_FREQUENCY)
    },
    ticker > 0 ? TICKER_FREQUENCY : null,
  )

  const dur = durationShift(ticker)

  return (
    <div className="auth">
      <div className="auth-title">{t('Авторизация')}</div>
      <div className="auth-content">
        <div>
          <div>{t('Выполняется вход в систему...')}</div>
          <div>{t('Обычно это занимает не более {{count}} секунд.', { count: 30 })}</div>
        </div>
      </div>
      <div className="auth-count">
        <div className="auth-counter">
          <svg viewBox="0 0 160 160" style={{ '--auth-count': TICKER_PERIOD / 1000 + 's' }}>
            <circle cx="50%" cy="50%" r="75" className="circle2" />
            <circle cx="50%" cy="50%" r="75" className="circle1" />
          </svg>
          <div className="auth-counter--value">{`${format(dur.minutes)}:${format(dur.seconds)}`}</div>
        </div>
      </div>
    </div>
  )
}

export default Refresh
