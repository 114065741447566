import { get } from './fetch'

export const getNews = (language = 'en', page = 1, size = 12) => {
  return get(`news?lang=${language}&page=${page}&size=${size}`, false)
}

export const getNewsItem = (id, language = 'en', key = null) => {
  const endpoint = key ? `news/${id}?lang=${language}&key=${key}` : `news/${id}?lang=${language}`

  return get(endpoint, false)
}
