import React, { useRef, useState } from 'react'
import CookieConsent from 'react-cookie-consent'
import { useTranslation } from 'react-i18next'
import useMount from 'react-use/lib/useMount'
import { DECLINE_COOKIE_DATE_LOCALSTORAGE_KEY } from 'shared/constants'

import { diffFromNow, getNowDate } from 'shared/utils/date'

const CookieAccept = () => {
  const { t } = useTranslation()
  const [show, setShow] = useState('byCookieValue')
  const cookieConsentRef = useRef()

  const handleAccept = () => {
    cookieConsentRef.current.accept()
  }

  const handleDecline = () => {
    setShow('hidden')
    cookieConsentRef.current.decline()
  }

  useMount(() => {
    if (
      localStorage.getItem(DECLINE_COOKIE_DATE_LOCALSTORAGE_KEY) &&
      diffFromNow(localStorage.getItem(DECLINE_COOKIE_DATE_LOCALSTORAGE_KEY))?.hours > 1
    ) {
      setShow('show')
    }
  })

  return (
    <CookieConsent
      buttonStyle={{ display: 'none' }}
      ref={cookieConsentRef}
      visible={show}
      onAccept={() => {
        setShow('byCookieValue')
        localStorage.removeItem(DECLINE_COOKIE_DATE_LOCALSTORAGE_KEY)
      }}
      onDecline={() => {
        localStorage.setItem(DECLINE_COOKIE_DATE_LOCALSTORAGE_KEY, getNowDate())
      }}
    >
      <div className="cookie">
        <div className="cookie--container">
          <div className="cookie--content">
            <div className="cookie--description">
              {t(
                'Этот веб-сайт использует файлы cookie. Оставаясь на сайте, вы даете согласие на обработку cookie и персональных данных. Пожалуйста, ознакомьтесь с нашей политикой конфиденциальности и использования файлов cookie.',
              )}
            </div>
            <div className="cookie--buttons">
              <button className="btn btn-w" onClick={handleAccept}>
                {t('Принять')}
              </button>
            </div>
          </div>

          <button className="cookie--close cookie-trigger" onClick={handleDecline}>
            <span>{t('Закрыть')}</span>
          </button>
        </div>
      </div>
    </CookieConsent>
  )
}

export default CookieAccept
