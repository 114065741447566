import React from 'react'
import { useTranslation } from 'react-i18next'

import useModalsStore from 'shared/stores/modals'

import Button from 'shared/components/Button'
import Modal from 'shared/components/Modal'

const InfoModal = ({ isOpen = false, onClose }) => {
  const { t } = useTranslation()
  const modals = useModalsStore()

  const handleClose = (ev) => {
    ev.preventDefault()
    onClose && onClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose} additionalClassName={'modal_sm'}>
      <a href="#" className="modal-close btn-modal-close" title="Закрыть" onClick={handleClose}>
        {t('Закрыть')}
      </a>
      <div className="modal-content">
        <div className="modal-body">
          <div className="message-sent">
            <div className="message-sent--title">{modals.props.title}</div>
            <div className="message-sent--description">{modals.props.content}</div>
            <div className="message-sent--buttons">
              <Button classes={['btn-modal-close']} onClick={handleClose} variant={'primary'}>
                {t('Закрыть')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default InfoModal
