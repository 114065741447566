import useUserStore from 'shared/stores/user'

import { getLoginUrl } from 'shared/utils/ui'

import { getProfile, receiveAuthKey } from 'shared/api/profile'

const useLogin = () => {
  const user = useUserStore()

  const loginByToken = async () => {
    const res = await receiveAuthKey()

    return getLoginUrl(res)
  }

  return (token, refreshToken) => {
    user.update({ accessToken: token, refreshToken })

    return getProfile()
      .then((profile) => {
        user.update({ ...profile, ref: null })

        loginByToken().then((url) => {
          window.location.href = url
        })
      })
      .catch((err) => console.log(err))
  }
}

export default useLogin
