import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const IntroSection = () => {
  const { t } = useTranslation()

  return (
    <section className="section section-home-intro">
      <div className="section-container">
        <div className="section-content">
          <div className="home-intro">
            <div className="home-intro--title">
              <Trans
                i18nKey={'CoinRealtor —<br/>простые инвестиции <br/>в традиционную нишу'}
                components={{
                  br: <br />,
                }}
              />
            </div>

            <div className="home-intro--description">
              {t('Купи криптовалюту, обеспеченную высоколиквидной недвижимостью')}
            </div>
            <div className="home-intro--buttons">
              <Link to={'/purchase/DCGT'} className="btn btn-primary">
                {t('Купить')}
              </Link>
            </div>
            <div className="home-intro--social">
              <ul className="intro-social-links">
                <li className="intro-social-links--item">
                  <a href="https://www.youtube.com/channel/UCM-Mwlv9WV2d2a2fHlUs-qg" target="_blank">
                    <svg width="3rem" height="2.4rem">
                      <use xlinkHref="#social-youtube" />
                    </svg>
                  </a>
                </li>
                <li className="intro-social-links--item">
                  <a href="https://t.me/CoinRealtor" target="_blank">
                    <svg width="2.9rem" height="2.4rem">
                      <use xlinkHref="#social-telegram" />
                    </svg>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default IntroSection
