import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import Breadcrumbs from 'components/Breadcrumbs'

import Auth from './components/Auth'
import Confirmation from './components/Confirmation'
import Payment from './components/Payment'
import Waiting from './components/Waiting'
import useStepsStore from './stores/steps'

export default () => {
  const { t } = useTranslation()
  const { token } = useParams()
  const { steps = [], setActiveStep } = useStepsStore()
  const currentStep = steps.find((step) => step.active)

  const showPurchase = true
  const canPreStep = (currentNum, num) => {
    return currentNum === 2 && num === 1
  }

  const handlePrevStep = (ev, num) => {
    ev.preventDefault()

    if (canPreStep(currentStep.number, num)) {
      setActiveStep(1)
    }
  }

  return (
    <div className="section section-buy-token">
      <div className="section-container">
        <div className="section-content">
          <div className="section-top">
            <Breadcrumbs
              currentCrumbs={[
                { path: '/purchase', breadcrumb: t('Покупка токенов {{token}}', { token }) },
                { path: '/purchase/:token', breadcrumb: null },
              ]}
            />
          </div>

          <div className="section-main">
            <div className="buy-token">
              <div className="buy-token--header">
                <div className="buy-token--title">{t('Покупка токенов {{token}}', { token })}</div>
              </div>
              {showPurchase && (
                <>
                  <div className="buy-token--steps">
                    <ul className="buy-token-steps">
                      {steps.map((step) => (
                        <li
                          key={step.number}
                          className={`buy-token-steps--item ${step.active ? 'is-active' : ''} ${
                            step.passed ? 'is-done' : ''
                          }`}
                        >
                          <div
                            className="buy-token-steps--item-title"
                            onClick={(ev) => handlePrevStep(ev, step.number)}
                            style={canPreStep(currentStep.number, step.number) ? { cursor: 'pointer' } : {}}
                          >
                            {t(step.label)}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>

                  {currentStep.number === 1 && <Payment token={token} />}
                  {currentStep.number === 2 && <Auth token={token} />}
                  {currentStep.number === 3 && <Waiting token={token} />}
                  {currentStep.number === 4 && <Confirmation token={token} />}
                </>
              )}
            </div>
            {!showPurchase && (
              <div className="buy-token-wait">
                <div className="buy-token-wait--title">{t('Покупка скоро запустится!')}</div>
                <div className="buy-token-wait--description">{t('Следите за новостями')}</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
