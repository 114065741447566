import { sortBy } from 'lodash/collection'
import React, { useEffect, useMemo, useState } from 'react'
import ReactCountryFlag from 'react-country-flag'
import { Trans, useTranslation } from 'react-i18next'
import useInterval from 'react-use/lib/useInterval'
import useMount from 'react-use/lib/useMount'
import * as yup from 'yup'

import useFormWrapper from 'shared/hooks/useFormWrapper'
import useLogin from 'shared/hooks/useLogin'
import useSettings from 'shared/hooks/useSettings'

import { durationShift, format } from 'shared/utils/counter'
import { getAvatar, getFullName } from 'shared/utils/user'

import { getReferrer } from 'shared/api/auth'
import { login as loginBot, requestConfirmation } from 'shared/api/auth/telegram-bot'

import Dropdown from 'shared/components/forms/Dropdown'
import FormField from 'shared/components/forms/FormField'
import Input from 'shared/components/forms/Input'

import useSessionStore from '../stores/session'

const validationSchema = yup.object().shape({
  phone: yup
    .string()
    .trim()
    .required(<Trans i18nKey={'Обязательное поле'} />),
  email: yup
    .string()
    .email(<Trans i18nKey={'Неправильный формат'} />)
    .trim()
    .required(<Trans i18nKey={'Обязательное поле'} />),
  country: yup
    .string()
    .trim()
    .required(<Trans i18nKey={'Обязательное поле'} />),
  emailCode: yup.number().typeError(<Trans i18nKey={'Значение должно быть числом'} />),
})

const EmailConfirmation = ({ form, getFieldError }) => {
  const COUNTER_PERIOD = 30_000
  const COUNTER_FREQUENCY = 1_000

  const { t } = useTranslation()
  const [sendCode, setSendCode] = useState(false)
  const [loading, setLoading] = useState(false)
  const [counter, setCounter] = useState(0)

  const runCounter = counter > 0
  const dur = durationShift(counter)

  const { email } = form.getValues()
  const sendDisabled = loading || form.formState.errors?.email
  const resendDisabled = loading || runCounter

  useInterval(
    () => {
      if (counter > 0) {
        setCounter(counter - COUNTER_FREQUENCY)
      }
    },
    runCounter ? COUNTER_FREQUENCY : null,
  )

  const handleSendCode = () => {
    setLoading(true)
    requestConfirmation({ email })
      .then(() => {
        setSendCode(true)
        setCounter(COUNTER_PERIOD)
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false))
  }

  const handleResendCode = () => {
    setSendCode(false)
  }

  return (
    <div className="form-group">
      <div className="email-verification-form">
        <FormField error={sendCode ? getFieldError('emailCode') : getFieldError('email')}>
          <label htmlFor="email" className="control-label">
            {sendCode ? t('Код из E-mail') : 'E-mail'}
          </label>

          <div className="form-group-row">
            <div className="form-group-row--input" style={{ display: sendCode ? 'block' : 'none' }}>
              <Input label={false} register={form.register} name={'emailCode'} />
              <div className="help-message">{t('Введите код из письма')}</div>
            </div>

            <div className="form-group-row--input" style={{ display: !sendCode ? 'block' : 'none' }}>
              <Input label={false} register={form.register} name={'email'} />
              <div className="help-message">{t('На email будет отправлен код')}</div>
            </div>

            <div className="form-group-row--button">
              {sendCode ? (
                <button className="btn btn-light" onClick={handleResendCode} disabled={resendDisabled}>
                  <span>{t('Отправить еще раз')}</span>
                </button>
              ) : (
                <button className="btn btn-light" onClick={handleSendCode} disabled={sendDisabled}>
                  <span>{t('Отправить код')}</span>
                </button>
              )}
              {runCounter && (
                <div className="help-message">
                  {t('Повторить через: {{count}} сек', { count: format(dur.seconds) })}
                </div>
              )}
            </div>
          </div>
        </FormField>
      </div>
    </div>
  )
}

const Preregister = () => {
  const { t, i18n } = useTranslation()
  const { session, reset } = useSessionStore()
  const { countries = [] } = useSettings()
  const login = useLogin()

  const [loading, setLoading] = useState(false)
  const [referrer, setReferrer] = useState(null)
  const ref = session.extra?.refKey
  const language = i18n.language

  const countryOptions = useMemo(() => {
    const result = Object.keys(countries).map((code) => {
      const country = countries[code]
      const lang = language.toUpperCase()

      const label = country.translations[lang] ? country.translations[lang].common : country.name.common

      return {
        label: label,
        value: country.code,
        icon: (
          <ReactCountryFlag
            countryCode={code}
            svg
            style={{
              width: '1.5em',
              height: '1.5em',
            }}
          />
        ),
      }
    })

    return sortBy(result, ['label'])
  }, [countries, language])

  const defaultCountry = useMemo(() => {
    const navigatorLanguage = navigator.language.split('-')

    let country = navigatorLanguage?.[0] || language

    country = country ? country.toUpperCase() : null

    if (Object.keys(countries).includes(country)) {
      return country
    }
    return null
  }, [countries, language])

  const sessionUser = {
    login: session.username,
    profile: {
      firstName: session.firstName,
      lastName: session.lastName,
      avatar: session.photoUrl,
    },
  }

  const { form, getFieldError, setFormValues, setFormErrors } = useFormWrapper(validationSchema, {
    mode: 'all',
    defaultValues: {},
  })

  const { country } = form.getValues()

  useMount(() => {
    form.watch()
  })

  useEffect(() => {
    if (!country && defaultCountry) {
      setFormValues({ country: defaultCountry })
    }
  }, [country, defaultCountry])

  useEffect(() => {
    if (ref && !referrer) {
      getReferrer(ref)
        .then((data) => setReferrer(data))
        .catch((err) => console.log(err))
    }
  }, [ref])

  const handleSubmit = (values) => {
    if (!form.formState.isValid || loading) {
      return
    }

    const payload = {
      ...values,
      hash: session.hash,
      ...(session.extra || {}),
    }

    setLoading(true)
    loginBot(payload, true)
      .then(() => {
        loginBot(payload, false)
          .then((data) => {
            login(data.token, data.refreshToken).then(() => reset())
          })
          .catch((err) => console.log(err))
          .finally(() => setLoading(false))
      })
      .catch((err) => {
        if (err.errors) {
          setFormErrors(err.errors)
        }
        setLoading(false)
      })
  }

  return (
    <div className="auth">
      <div className="auth-title">{t('Настройка профиля')}</div>

      <div className="auth-content">
        <div>{t('Для завершения входа в систему заполните следующие поля')}</div>
      </div>

      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <div className="auth-form">
          <div className="form-group">
            <div className="logged-info">
              <div className="usercard">
                <div className="usercard--image">
                  <img src={getAvatar(sessionUser)} alt={sessionUser.login} />
                </div>
                <div className="usercard--content">
                  <div className="usercard--name">{getFullName(sessionUser)}</div>
                  <div className="usercard--nick">{sessionUser.login}</div>
                </div>
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="form-group">
              <label htmlFor="sponsor" className="control-label">
                {t('Ваш личный Агент:')}
              </label>
              <input
                type="text"
                className="form-control"
                id="sponsor"
                value={referrer ? referrer.login : 'Administrator'}
                readOnly={true}
              />
            </div>
          </div>

          <FormField error={getFieldError('country')}>
            <label className="control-label">{t('Страна')}</label>
            <Dropdown name={'country'} options={countryOptions} control={form.control} defaultValue={defaultCountry} />
          </FormField>

          <FormField error={getFieldError('phone')}>
            <Input label={t('Телефон')} register={form.register} name={'phone'} />
          </FormField>

          <EmailConfirmation form={form} getFieldError={getFieldError} />
        </div>

        <div className="auth-buttons">
          <button
            type="submit"
            className={`btn btn-primary ${loading ? 'loading' : ''}`}
            disabled={!form.formState.isValid || loading}
          >
            {t('Войти')}
          </button>
        </div>
      </form>
    </div>
  )
}

export default Preregister
