import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const Fail = ({ errors = null }) => {
  const { t } = useTranslation()

  const hasErrors = errors !== null

  console.log(errors, 'sss')
  return (
    <div className="auth">
      <div className="auth-title">{t('Авторизация')}</div>
      <div className="auth-content">
        <div className="action-message action-message_fail">
          <div className="action-message--content">
            <div className="action-message--title">{t('Авторизация отклонена!')}</div>
            <div className="action-message--title">{t('Попробуйте снова')}</div>
          </div>
        </div>
        <div style={{ display: 'none' }} />
      </div>
      {hasErrors && errors?.login && (
        <div className="auth-message">
          <div className="tg-message">
            <div className="tg-message--title">{t(errors?.login)}</div>

            <div className="tg-message--content">
              <div>
                <Trans
                  i18nKey={'Для этого войдите в настройки <a>Telegram</a>, кликните на свой номер телефона.'}
                  components={{ a: <a href={'https://telegram.org/'} target={'_blank'} /> }}
                />
              </div>
              <div>{t('Далее нажмите на «Имя пользователя» и выберите подходящий логин!')}</div>
            </div>
          </div>
        </div>
      )}

      <div className="auth-content">
        {!hasErrors && (
          <div>
            <Trans
              i18nKey={
                'Для входа в личный кабинет вам необходимо иметь установленное приложение <a>Telegram</a> на вашем устройстве.'
              }
              components={{
                a: <a href="https://telegram.org" target="_blank" />,
              }}
            />
          </div>
        )}

        <div>
          <div>
            <Trans
              i18nKey={'Если у вас возникли проблемы, <a>напишите нам</a> об этом.'}
              components={{
                a: <a href="/#contact-section" />,
              }}
            />
          </div>
        </div>
      </div>
      <div className="auth-buttons">
        <Link to="/auth" className="btn btn-primary">
          <svg width="1.9rem" height="1.6rem">
            <use xlinkHref="#social-telegram" />
          </svg>
          <span>{t('Войти')}</span>
        </Link>
      </div>
    </div>
  )
}

export default Fail
