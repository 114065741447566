import React from 'react'
import { useTranslation } from 'react-i18next'
import NewsSection from 'screens/News/components/NewsSection'

import MainButton from 'shared/components/MainButton'

const CorpSection = () => {
  const { t } = useTranslation()

  return (
    <section className="section section-corp">
      <div className="section-container">
        <div className="section-content">
          <div className="home-mission">
            <div className="home-mission--content">
              <div className="home-mission--title">{t('Наша миссия')}</div>

              <div className="home-mission--description">
                {`
                  ${t(
                    'CoinRealtor стремится повысить эффективность и ликвидность рынка токенизированной недвижимости, предлагая специализированную платформу для инвесторов и управляющих компаний. Наша платформа позволяет проводить простые, быстрые, экономичные и глобальные распродажи токенов, обеспеченные высоколиквидной недвижимостью.',
                  )}

                  ${t(
                    'CoinRealtor – это, прежде всего, гибкая и практичная платформа для инвестирования в токенизированную недвижимость, управления токенизированными активами, автоматического соблюдения заложенных в блокчейн требований и прав, управления инвесторами и других различных решений.',
                  )}

                  ${t(
                    'Наша миссия – обеспечить доступность инвестирования даже незначительной суммы в перспективную отрасль. Мы открываем возможности покупки токенизированной недвижимости, которая находится в другом городе или даже в другой стране. Таким образом, при токенизации недвижимости базовым обеспечением токена является недвижимое имущество, а в скором времени, и виртуальная недвижимость.',
                  )}`}
              </div>
            </div>

            <div className="home-mission--media">
              <img src="./uploads/content/home-mission.jpg" alt="" className="home-mission--image" />
            </div>
          </div>

          <div className="home-team">
            <div className="home-team--title">{t('Команда CoinRealtor')}</div>

            <div className="home-team--content">
              {`
                ${t(
                  'CoinRealtor – это команда высококомпетентных специалистов в сфере криптовалют и традиционной недвижимости.  Всю нашу команду объединяет одна общая цель - привлечение криптовалютных средств, чтобы дать криптоинвесторам возможность получать доход от токенизированной недвижимости при минимальных вложениях.',
                )}
                ${t(
                  'Если вы хотите инвестировать, наша команда проконсультирует по вопросам вложений в традиционную нишу, через призму криптовалютного мира.',
                )}
              `}
            </div>

            <div className="home-team--buttons">
              <MainButton title={'Присоединиться'} loggedTitle={'Купить токены'} />
            </div>
          </div>

          <NewsSection />
        </div>
      </div>
    </section>
  )
}

export default CorpSection
