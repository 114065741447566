import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useParams } from 'react-router-dom'

import Breadcrumbs from 'components/Breadcrumbs'

import useQuery from 'shared/hooks/useQuery'

import useUserStore from 'shared/stores/user'

import { parseAndFormat } from 'shared/utils/date'

import { getNewsItem } from 'shared/api/news'

const NewsDetail = () => {
  const user = useUserStore()
  const { i18n } = useTranslation()
  const params = useParams()
  const query = useQuery()

  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const language = user.language ?? i18n.language
  const key = query.get('key')

  const fetchData = (id, language, key = null) => {
    setLoading(true)
    getNewsItem(id, language, key)
      .then((res) => {
        setData(res)
        setLoading(false)
      })
      .catch((e) => {
        setError(e)
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchData(params.slug, language, key)
  }, [language, params.slug])

  if (error) {
    return <Navigate to={'/not-found'} />
  }

  return (
    <div className="section section-news">
      <div className="section-container">
        <div className="section-content">
          <div className="section-top">
            <Breadcrumbs currentCrumbs={[{ path: 'news/:slug', breadcrumb: data?.title }]} />
          </div>

          <div className="section-main">
            <div className={`news-article ${loading ? 'loading' : ''}`}>
              <div className="news-article--header">
                <div className="news-article--title">{data?.title}</div>

                <div className="news-article--meta">
                  <div className="news-article--date">{parseAndFormat(data?.publishedAt ?? '', 'dd.MM.yyyy')}</div>
                </div>
              </div>
              <div className="news-article--media">
                <img src={data?.media?.large} alt={data?.title} />
              </div>

              <div className="news-article--content">
                <p dangerouslySetInnerHTML={{ __html: data?.content }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewsDetail
