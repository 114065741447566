import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import useInterval from 'react-use/lib/useInterval'
import useSearchParam from 'react-use/lib/useSearchParam'

import useLogin from 'shared/hooks/useLogin'

import useUserStore from 'shared/stores/user'

import { durationShift, format } from 'shared/utils/counter'
import { getLoginUrl } from 'shared/utils/ui'

import { login as loginPassport } from 'shared/api/auth/telegram-passport'
import { getProfile, receiveAuthKey } from 'shared/api/profile'

const TICKER_PERIOD = 30000
const TICKER_FREQUENCY = 1000

const Waiting = ({ onError }) => {
  const { t } = useTranslation()
  const ssid = useSearchParam('ssid')
  const tgStatus = useSearchParam('tg_passport')

  const navigate = useNavigate()
  const user = useUserStore()
  const login = useLogin()

  const [ticker, setTicker] = useState(TICKER_PERIOD)
  const [authUser, setAuthUser] = useState(null)

  useEffect(() => {
    if (tgStatus === null) {
      navigate('/auth')
    }
    if (tgStatus === 'cancel') {
      navigate('/auth/fail')
    }
  }, [tgStatus])

  useInterval(
    () => {
      setTicker(ticker - TICKER_FREQUENCY)
    },
    ticker > 0 ? TICKER_FREQUENCY : null,
  )

  useInterval(
    () => {
      loginPassport({ ssid })
        .then((data) => {
          if (data) {
            const success = data?.token !== undefined

            if (!success && data?.errors) {
              onError && onError(data.errors)
              navigate('/auth/fail')
              return
            }

            setAuthUser(data)

            login(data.token, data.refreshToken)
          }
        })
        .catch((err) => console.log(err))
    },
    authUser === null ? 5000 : null,
  )

  const dur = durationShift(ticker)

  return (
    <div className="auth">
      <div className="auth-title">{t('Авторизация')}</div>
      <div className="auth-content">
        <div>
          <div>{t('Выполняется вход в систему...')}</div>
          <div>{t('Обычно это занимает не более {{count}} секунд.', { count: 30 })}</div>
        </div>
      </div>
      <div className="auth-count">
        <div className="auth-counter">
          <svg viewBox="0 0 160 160" style={{ '--auth-count': TICKER_PERIOD / 1000 + 's' }}>
            <circle cx="50%" cy="50%" r="75" className="circle2" />
            <circle cx="50%" cy="50%" r="75" className="circle1" />
          </svg>
          <div className="auth-counter--value">{`${format(dur.minutes)}:${format(dur.seconds)}`}</div>
        </div>
      </div>
    </div>
  )
}

export default Waiting
