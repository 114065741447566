export const getObjects = () => {
  return new Promise((resolve) => {
    resolve({
      data: [
        {
          token: 'DCGT',
          name: 'Жилой комплекс GROVE',
          district: 'DUBAI CREEK BEACH, Дубай, ОАЭ.',
          description:
            'Один из самых перспективных и быстрорастущих районов Дубая, с самым высоким строящимся зданием в мире — Dubai Creek Tower, плановая высота которого 1345 метров. Dubai Creek станет вторым центром города с образом жизни, которого нет больше нигде в Дубаи.',
        },
      ],
    })
  })
}
