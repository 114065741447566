import React from 'react'
import { useTranslation } from 'react-i18next'
import { USER_STATUS_NEW } from 'shared/constants'

import { getAvatar, getFullName } from 'shared/utils/user'

import Sponsor from './Sponsor'

const ResultAuth = ({ user, loading = false, showSponsor = false, onCreate, onCancel }) => {
  const { t } = useTranslation()
  const isUserNew = user.status === USER_STATUS_NEW

  return (
    <>
      <div className="buy-token-auth--info">
        <div className="logged-info">
          <div className="usercard">
            <div className="usercard--image">
              <img src={getAvatar(user)} alt={user.login} />
            </div>
            <div className="usercard--content">
              <div className="usercard--name">{isUserNew ? t('Новый пользователь') : getFullName(user)}</div>
              <div className="usercard--nick">
                {user.email} {isUserNew ? '' : `(${user.login})`}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showSponsor && (
        <div className="buy-token-auth--sponsor">
          <Sponsor user={user} />
        </div>
      )}
      <div className="buy-token-auth--buttons">
        <button className={`btn btn-primary ${loading ? 'loading' : ''}`} onClick={onCreate}>
          {t('Оплатить')}
        </button>
        <button className={`btn`} onClick={() => onCancel && onCancel()}>
          {t('Отменить')}
        </button>
      </div>
    </>
  )
}

export default ResultAuth
