import { truncate } from 'lodash'
import React from 'react'
import { Link } from 'react-router-dom'

import { parseAndFormat } from 'shared/utils/date'

const NewsCard = ({ data = {} }) => {
  const url = `/news/${data.slug}`

  return (
    <li className="home-news-list--item">
      <div className="news-list-item">
        <div className="news-list-item--image">
          <Link to={url} title={data.title}>
            <img src={data?.media?.medium} alt={data.title} />
          </Link>
        </div>
        <div className="news-list-item--content">
          <div className="news-list-item--meta">
            <div className="news-list-item--date">{parseAndFormat(data?.publishedAt ?? '', 'dd.MM.yyyy')}</div>
          </div>
          <div className="news-list-item--title">
            <Link to={url} title={data.title}>
              {data.title}
            </Link>
          </div>
          <div className="news-list-item--description">{truncate(data.excerpt, { length: 75 })}</div>
        </div>
      </div>
    </li>
  )
}

export default NewsCard
