import { get, post } from '../fetch'

export const getToken = (token) => {
  return get(`tokens/${token}`)
}

export const getTokenRates = (token, period = 'week', future = false) => {
  return get(`tokens/${token}/rates?period=${period}&future=${Number(future)}`)
}

export const purchaseCalculation = (token, payload, withFee = true) => {
  return post(`tokens/${token}/purchase/calculation?fee=${Number(withFee)}`, payload, false)
}
