import { isString } from 'lodash/lang'
import React, { useEffect, useRef, useState } from 'react'
import { useController } from 'react-hook-form'
import { useClickAway } from 'react-use'

const Dropdown = ({
  control,
  name,
  defaultValue = '',
  options = [],
  labelKey = 'label',
  valueKey = 'value',
  descriptionKey = 'description',
  iconKey = 'icon',
  disabled = false,
  onChange,
}) => {
  const { field } = useController({ control, name, defaultValue })
  const [opened, setOpened] = useState(false)
  const [selectedOption, setSelectedOption] = useState()
  const ref = useRef()

  useEffect(() => {
    if (!selectedOption) {
      const option = options.find((opt) => opt?.[valueKey] === field.value)

      if (option) {
        setSelectedOption(option)
      }
    }
  }, [field, selectedOption])

  useClickAway(ref, () => {
    setOpened(false)
  })

  const handleOpenList = (ev) => {
    ev.preventDefault()
    setOpened(!opened)
  }

  const handleSelectOption = (ev, option) => {
    ev.preventDefault()
    setOpened(false)
    setSelectedOption(option)
    field.onChange(option.value)

    onChange && onChange(option)
  }

  return (
    <div className={`dropdown ${opened ? 'is-active' : ''}`} ref={ref}>
      <button className="dropdown-button" onClick={handleOpenList} disabled={disabled}>
        <div className="dropdown-button--content">
          <div className="currency-card">
            {selectedOption?.[iconKey] && (
              <div className="currency-card--image">
                {isString(selectedOption[iconKey]) ? (
                  <img src={selectedOption[iconKey]} alt={selectedOption?.[labelKey]} />
                ) : (
                  selectedOption[iconKey]
                )}
              </div>
            )}
            <div className="currency-card--content">
              <div className="currency-card--title">{selectedOption?.[labelKey]}</div>
              {selectedOption?.[descriptionKey] && (
                <div className="currency-card--subtitle">{selectedOption[descriptionKey]}</div>
              )}
            </div>
          </div>
        </div>
        <svg>
          <use xlinkHref="#caret" />
        </svg>
      </button>

      <ul className="dropdown-list">
        {options
          .filter((opt) => opt.value !== selectedOption?.value)
          .map((option, key) => (
            <li key={`item_${key}`} className={`dropdown-list--item ${option.value === selectedOption?.value}`}>
              <a
                href="#"
                className="dropdown-list--item-button dropdown-option"
                disabled={option?.disabled}
                title={option?.[labelKey]}
                onClick={(ev) => handleSelectOption(ev, option)}
              >
                <div className="currency-card">
                  {option?.[iconKey] && (
                    <>
                      {isString(option[iconKey]) ? (
                        <div className="currency-card--image">
                          <img src={option[iconKey]} alt={option[labelKey]} />
                        </div>
                      ) : (
                        option[iconKey]
                      )}
                    </>
                  )}
                  <div className="currency-card--content">
                    <div className="currency-card--title">{option?.[labelKey]}</div>
                    {option?.[descriptionKey] && (
                      <div className="currency-card--subtitle">{option[descriptionKey]}</div>
                    )}
                  </div>
                </div>
              </a>
            </li>
          ))}
      </ul>
    </div>
  )
}

export default Dropdown
