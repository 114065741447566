import { useEffect, useState } from 'react'
import { useDebounce } from 'react-use'
import { CURRENCY_DCGT, TYPE_AMOUNT, TYPE_CURRENCY_AMOUNT } from 'shared/constants'

import { purchaseCalculation } from 'shared/api/tokens'

import { recomposeCurrency, roundCurrencyAmount } from '../utils/billing'

const useCalculator = (form, selectedCurrency, activeAmount) => {
  const [loading, setLoading] = useState(false)

  const { amount, currencyAmount } = form.getValues()

  useDebounce(
    () => {
      if (amount && activeAmount === TYPE_AMOUNT) {
        handleCalculation({
          amount,
        })
      }
    },
    400,
    [amount],
  )

  useDebounce(
    () => {
      if (currencyAmount && activeAmount === TYPE_CURRENCY_AMOUNT) {
        handleCalculation({
          currencyAmount,
        })
      }
    },
    400,
    [currencyAmount],
  )

  useEffect(() => {
    if (currencyAmount || amount)
      if (activeAmount === TYPE_AMOUNT) {
        handleCalculation({
          amount,
        })
      } else {
        handleCalculation({
          currencyAmount,
        })
      }
  }, [selectedCurrency])

  const handleCalculation = (amountObj) => {
    setLoading(true)

    const { currency, blockchain } = recomposeCurrency(selectedCurrency)
    const payload = { currency, blockchain, ...amountObj }

    purchaseCalculation(CURRENCY_DCGT, payload)
      .then((res) => {
        const amount = res.amount > 0 ? res.amount : 0

        form.setValue(
          activeAmount === TYPE_AMOUNT ? TYPE_CURRENCY_AMOUNT : TYPE_AMOUNT,
          roundCurrencyAmount(amount, currency),
        )
        form.trigger(activeAmount === TYPE_AMOUNT ? TYPE_CURRENCY_AMOUNT : TYPE_AMOUNT)
      })
      .finally(() => setLoading(false))
  }

  return { loading }
}

export default useCalculator
