import { post } from '../fetch'

export const calculation = (token, payload, withFee = true) => {
  return post(`tokens/${token}/purchase/calculation?fee=${Number(withFee)}`, payload, false)
}

export const purchase = (token, payload, withAuth = true) => {
  return post(`tokens/${token}/purchase`, payload, withAuth)
}

export const requestEmailConfirmation = (token, payload) => {
  return post(`tokens/${token}/purchase/request-confirmation`, payload)
}

export const emailConfirmation = (token, payload) => {
  return post(`tokens/${token}/purchase/confirmation`, payload)
}
