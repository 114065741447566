import React from 'react'
import { useMount } from 'react-use'
import { DISCOUNT_END_DATE, DISCOUNT_LOCALSTORAGE_KEY, DISCOUNT_START_DATE } from 'shared/constants'

import useModalsStore from 'shared/stores/modals'

import { DEFAULT_PATTERN, getNowDate, parse } from 'shared/utils/date'

import InfoModal from 'shared/components/InfoModal'

const DATE_FORMAT = 'dd.MM.yyyy'

const ModalsProvider = ({ children }) => {
  const modals = useModalsStore()
  const currentDiscountDate = localStorage.getItem(DISCOUNT_LOCALSTORAGE_KEY) ?? ''

  const handleClose = async (type) => {
    await modals.hide(type)
  }

  const handleOpenDiscountModal = () => {
    if (
      !!currentDiscountDate &&
      (+parse(currentDiscountDate, DATE_FORMAT) < +parse(DISCOUNT_START_DATE, DEFAULT_PATTERN) ||
        +parse(currentDiscountDate, DATE_FORMAT) > +parse(DISCOUNT_END_DATE, DEFAULT_PATTERN))
    )
      return false

    setTimeout(() => {
      modals.show('discount')
      localStorage.setItem(DISCOUNT_LOCALSTORAGE_KEY, getNowDate(DATE_FORMAT))
    }, 2000)
  }

  useMount(() => {
    if (currentDiscountDate !== getNowDate(DATE_FORMAT)) {
      handleOpenDiscountModal()
    }
  })

  return (
    <>
      {children}
      {modals.info && <InfoModal isOpen={modals.info} onClose={() => handleClose('info')} />}
    </>
  )
}

export default ModalsProvider
