import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Element } from 'react-scroll'

const AboutSection = () => {
  const { t } = useTranslation()

  return (
    <Element className="section section-about" name={'about-section'}>
      <div className="section-container">
        <div className="section-content">
          <div className="home-about">
            <div className="home-about--content">
              <div className="home-about--title">{t('Кто мы?')}</div>

              <div className="home-about--description">
                <p>
                  {t(
                    'CoinRealtor — это платформа по токенизации недвижимости в разных уголках мира. Мы предлагаем уникальную возможность инвестировать в традиционную нишу — в недвижимость, но через призму криптовалютного мира. Мы убеждены, что взаимодействовать с недвижимостью через криптовалюты намного удобнее, чем традиционным образом. Прежде всего — это надежно и безопасно, платежи проходят моментально, с минимальными затратами на транзакции, и самое главное — анонимно.',
                  )}
                </p>

                <p className="highlight">
                  {t('CoinRealtor не выступает в роли посредника по продаже недвижимости.')}&nbsp;
                  {t('Платформа предлагает приобрести перспективную криптовалюту, обеспеченную недвижимостью.')}
                </p>
              </div>
            </div>

            <div className="home-about--media">
              <img className="home-about--image" src="uploads/content/home-about-1.jpg" />
            </div>
          </div>
          <div className="home-about home-about_reverse">
            <div className="home-about--content">
              <div className="home-about--title">{t('Что такое токенизация?')}</div>

              <div className="home-about--description">
                <p>
                  {t(
                    'Если простыми словами, токенизация недвижимости — это процесс создания цифрового токена, который представляет собой виртуальную долю в недвижимости.',
                  )}
                </p>
                <p>
                  {t(
                    'Для токенизации используется технология блокчейн, которая повышает надежность и защищенность данных, а также автоматизирует многие процессы.',
                  )}
                </p>
              </div>
              <div className="home-about--title">{t('Виды токенизиции')}</div>

              <div className="home-about--description">
                {t(
                  'Платформа открывает возможность токенизировать перспективную жилую и коммерческую недвижимость, земельные участки в любом уголке мира, а также виртуальную недвижимость и виртуальные участки в различных метавселенных.',
                )}
              </div>
            </div>

            <div className="home-about--media">
              <img className="home-about--image" src="uploads/content/home-about-2.jpg" />
            </div>
          </div>
          <div className="home-about home-about_video">
            <div className="home-about--content">
              <div className="home-about--title">{t('Купи криптовалюту, обеспеченную недвижимостью')}</div>

              <div className="home-about--description">
                {t(
                  'Изучи доступные предложения по токенизированным объектам и купи криптовалюту, стоимость которой растет по законам рынка недвижимости',
                )}
              </div>

              <div className="home-about--buttons">
                <Link to="/purchase/DCGT" className="btn btn-primary">
                  {t('Купить')}
                </Link>
              </div>
            </div>

            <div className="home-about--media">
              <img src="uploads/video-preview.jpg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </Element>
  )
}

export default AboutSection
