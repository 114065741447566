import { Splide, SplideSlide } from '@splidejs/react-splide'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import useTokenDetail from 'shared/hooks/useTokenDetail'

const objectSliderConfig = {
  type: 'loop',
  interval: 5000,
  speed: 1500,
  arrows: false,
}
const AboutSection = () => {
  const { t } = useTranslation()
  const { tokenDetails } = useTokenDetail('DCGT')
  const coords = tokenDetails.data?.coordinates ?? {}

  return (
    <>
      <section className="section section-object-about">
        <div className="section-container">
          <div className="section-content">
            <div className="object-about">
              <div className="object-about--media">
                <img src="/uploads/content/object-about-1.jpg" alt="" />
              </div>
              <div className="object-about--content">
                <div className="object-about--title">{t('В двух шагах от набережной')}</div>
                <div className="object-about--description">
                  {t(
                    'Creek Beach протягивается вдоль мерцающего канала, который проходит между островом и материком - это идеальное место для обеспечения инвестиционных токенов. Покупая токен вы получаете приятные бонусы, которые усиливают привлекательность объекта - это 700 метров нетронутых белых песков, пейзажный бассейн и захватывающие виды на закат.',
                  )}
                </div>
              </div>
            </div>

            <div className="object-about object-about_reverse">
              <div className="object-about--media">
                <img src="/uploads/content/object-about-2.jpg" alt="" />
              </div>
              <div className="object-about--content">
                <div className="object-about--title">{t('Элегантно-городской стиль')}</div>
                <div className="object-about--description">
                  {t(
                    'Grove - это инновационный жилой квартал, который сочетает в себе исключительное расположение, выдающийся дизайн и эклектичный образ жизни. Комплекс состоит из пяти элегантных зданий - это идеальные дома, вдали от городского шума с благоприятной пешеходной средой.',
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section section-object-secondary">
        <div className="section-container">
          <div className="section-content">
            <Splide options={objectSliderConfig}>
              <SplideSlide>
                <img src="/uploads/content/slider2-1.jpg" alt="" className="object-secondary--image" />
              </SplideSlide>
              <SplideSlide>
                <img src="/uploads/content/slider2-2.jpg" alt="" className="object-secondary--image" />
              </SplideSlide>
              <SplideSlide>
                <img src="/uploads/content/slider2-3.jpg" alt="" className="object-secondary--image" />
              </SplideSlide>
              <SplideSlide>
                <img src="/uploads/content/slider2-4.jpg" alt="" className="object-secondary--image" />
              </SplideSlide>
            </Splide>
          </div>
        </div>
      </section>
      <section className="section section-object-about section-object-about_map">
        <div className="section-container">
          <div className="section-content">
            <div className="object-about object-about_reverse">
              <div className="object-about--media">
                <img src="/uploads/content/object-about-3.jpg" alt="" />
              </div>
              <div className="object-about--content">
                <div className="object-about--title">{t('Все для комфортной жизни')}</div>
                <div className="object-about--description">
                  <p>
                    <Trans
                      i18nKey={
                        'Вкладывая в <b>{{token}}</b>, вы инвестируете в место для непревзойденного образа жизни, где сосредоточено все для комфорта. На территории находится пейзажный бассейн и прямой доступ к 700-метровому пляжу, а также:'
                      }
                      components={{ b: <b /> }}
                      values={{ token: 'DC Grove Token' }}
                    />
                  </p>
                  <ul>
                    <li>{t('общий бассейн и детский бассейн;')}</li>
                    <li>{t('полностью оборудованный тренажерный зал;')}</li>
                    <li>{t('обширная открытая игровая зона;')}</li>
                    <li>{t('многоцелевая общая комната;')}</li>
                    <li>{t('внутренняя плаза.')}</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="object-features">
              <div className="object-features--item">
                <div className="object-features--item-icon">
                  <img src="/images/object-desc-1.svg" alt="" />
                </div>
                <div className="object-features--item-content">
                  <div className="object-features--item-title">{t('Низкие риски с низкой волатильностью')}</div>
                  <div className="object-features--item-description">
                    {t(
                      'Одно из ключевых преимуществ нашей токенизированной недвижимости в том, что она не может полностью обесценится и ее стоимость не упадет до нуля, как, например, бывает с криптовалютами, ценными бумагами или бизнесом.',
                    )}
                  </div>
                </div>
              </div>
              <div className="object-features--item">
                <div className="object-features--item-icon">
                  <img src="/images/object-desc-2.svg" alt="" />
                </div>
                <div className="object-features--item-content">
                  <div className="object-features--item-title">{t('Рост капитала')}</div>
                  <div className="object-features--item-description">
                    {t(
                      'На рынке токенизированной недвижимости действуют такие же правила, как и в традиционной нише. Стоимость  недвижимости растет или остается стабильной. Но даже если цена упадет – это будет несущественная разница и в долгосрочной перспективе стоимость выровняется до прежнего уровня.',
                    )}
                  </div>
                </div>
              </div>
              <div className="object-features--item">
                <div className="object-features--item-icon">
                  <img src="/images/object-desc-3.svg" alt="" />
                </div>
                <div className="object-features--item-content">
                  <div className="object-features--item-title">{t('Высокая ликвидность')}</div>
                  <div className="object-features--item-description">
                    {t(
                      'Простота оценки, токенизированное владение и гибкость транзакций способствуют более высокому уровню ликвидности. Таким образом токенизированная недвижимость создает возможности для увеличения доходов.',
                    )}
                  </div>
                </div>
              </div>

              <div className="object-features--item object-features--item_expert">
                <div className="object-features--item-icon">
                  <img src="/images/object-sub-desc.svg" alt="" />
                </div>
                <div className="object-features--item-content">
                  <div className="object-features--item-description">
                    {t(
                      'Экспертная оценка жилого комплекса Grove Creek Beach Dubai показывает большую инвестиционную привлекательность объекта недвижимости, с возможностями получения значительного дополнительного дохода от сдачи в аренду площадей после ввода в эксплуатации всей территории Dubai Creek.',
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="object-map">
              <iframe
                src={`https://www.google.com/maps?q=${coords.lat}, ${coords.lng}&z=15&output=embed`}
                width="100%"
                height="450"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default AboutSection
