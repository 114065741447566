import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { BLOCKCHAIN_BSC, INVOICE_STATUS_PAID } from 'shared/constants'

import { getBlockchainIcon, getBlockchainLabel } from 'shared/utils/billing'
import { parseAndFormat } from 'shared/utils/date'
import { asMoney } from 'shared/utils/money'

import useStepsStore from '../stores/steps'
import { getStepData } from '../utils/steps'

const Confirmation = ({ token }) => {
  const { t } = useTranslation()
  const { steps, resetSteps } = useStepsStore()
  const navigate = useNavigate()

  const payment = getStepData(3, steps)

  const isSuccess = payment.status === INVOICE_STATUS_PAID
  const amount = payment.extra.amount
  const currencyAmount = payment.amount.value
  const currency = payment.amount.currency
  const date = payment.paidAt || payment.createdAt
  const account = payment.extra.account
  const blockchainReceipt = BLOCKCHAIN_BSC

  const handleGoToHome = (ev) => {
    ev.preventDefault()
    navigate('/')
    resetSteps()
  }

  const handleReset = (ev) => {
    ev.preventDefault()
    resetSteps()
  }

  return (
    <>
      {isSuccess ? (
        <div className="buy-token--info">
          <div className="action-message action-message_success">
            <div className="action-message--content">
              <div className="action-message--title">
                {t('Информация о транзакции №')}
                {payment.id}
              </div>
              <div className="action-message--description">{t('Транзакция прошла успешно!')}</div>
            </div>
          </div>
        </div>
      ) : (
        <div className="buy-token--info">
          <div className="action-message action-message_fail">
            <div className="action-message--content">
              <div className="action-message--title">{t('Транзакция отклонена!')}</div>
              <div className="action-message--title">{t('Попробуйте снова')}</div>
            </div>
          </div>
        </div>
      )}

      <div className="buy-token--info">
        <div className="transaction-details">
          <div className="transaction-details--row">
            <div className="transaction-details--item">
              <div className="transaction-details--item-title">{t('Сумма')}</div>
              <div className="transaction-details--item-value">{asMoney(currencyAmount, currency)}</div>
            </div>

            <div className="transaction-details--item">
              <div className="transaction-details--item-title">{isSuccess ? t('Дата оплаты') : t('Дата попытки')}</div>
              <div className="transaction-details--item-value">{parseAndFormat(date)}</div>
            </div>
          </div>

          <div className="transaction-details--row">
            <div className="transaction-details--item">
              <div className="transaction-details--item-title">{t('Количество токенов:')}</div>
              <div className="transaction-details--item-value">{asMoney(amount, token)}</div>
            </div>

            {/*            <div className="transaction-details--item" >
              <div className="transaction-details--item-title">{t('Кошелек получения')}</div>
              <div className="transaction-details--item-value">{account}</div>
            </div> */}

            <div className="transaction-details--item">
              <div className="transaction-details--item-title">{t('Блокчейн получения')}</div>
              <div className="transaction-details--item-value">
                <div className="blockchain-card">
                  <div className="blockchain-card--image">
                    <img src={getBlockchainIcon(blockchainReceipt)} alt={getBlockchainLabel(blockchainReceipt)} />
                  </div>
                  <div className="blockchain-card--content">
                    <div className="blockchain-card--title">{getBlockchainLabel(blockchainReceipt)}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="buy-token--form-buttons">
        <a href="#" className="btn btn-primary" title={t('На главную')} onClick={handleGoToHome}>
          {t('На главную')}
        </a>
        <a href="#" className="btn" title={t('Начать заново')} onClick={handleReset}>
          {t('Начать заново')}
        </a>
      </div>
    </>
  )
}

export default Confirmation
