import { useState } from 'react'
import { useDebounce } from 'react-use'
import useSetState from 'react-use/lib/useSetState'
import { BLOCKCHAIN_TRX, CURRENCY_USDT } from 'shared/constants'

import { formatNumber } from 'shared/utils/number'

import { calculation } from 'shared/api/tokens/purchase'

const useCalculator = (token, onChangeAmount, onChangeCurrencyAmount, timeout = 350) => {
  const [amount, setAmount] = useState('')
  const [currencyAmount, setCurrencyAmount] = useState('')
  const [additional, setAdditional] = useState({
    defaultAmount: {
      value: 0,
      currency: null,
    },
    discount: {
      value: 0,
      currency: null,
    },
  })
  const [extra, setExtra] = useSetState({
    currency: null,
    blockchain: null,
    withFee: true,
  })

  const [changedField, setChangeField] = useState('amount')

  const calculateAmount = (amount, currency = CURRENCY_USDT, blockchain = BLOCKCHAIN_TRX, withFee = true) => {
    if (amount === '') {
      onChangeAmount(0)
    }

    setChangeField('amount')
    setExtra({ currency, blockchain, withFee })
    setAmount(formatNumber(amount))
  }

  const calculateCurrencyAmount = (amount, currency = CURRENCY_USDT, blockchain = BLOCKCHAIN_TRX, withFee = true) => {
    if (amount === '') {
      onChangeCurrencyAmount(0)
    }

    setChangeField('currencyAmount')
    setExtra({ currency, blockchain, withFee })
    setCurrencyAmount(formatNumber(amount))
  }

  const calculateField = (field, value, payload = {}) => {
    const newExtra = { ...extra, ...payload, [field]: value }

    setExtra(newExtra)

    if (changedField === 'amount') {
      receiveAmount('amount', token, amount, newExtra, onChangeAmount)
    } else {
      receiveAmount('currencyAmount', token, currencyAmount, newExtra, onChangeCurrencyAmount)
    }
  }

  const receiveAmount = (changedField, token, amount, extra, callback) => {
    const payload = {
      ...extra,
      [changedField]: amount,
    }

    if (!payload[changedField] || !payload.currency) {
      return
    }

    calculation(token, payload, payload.withFee).then((result) => {
      callback && callback(result.amount, result.currency)
      setAdditional(result.additional)
    })
  }

  useDebounce(() => receiveAmount('amount', token, amount, extra, onChangeAmount), timeout, [amount])

  useDebounce(() => receiveAmount('currencyAmount', token, currencyAmount, extra, onChangeCurrencyAmount), timeout, [
    currencyAmount,
  ])

  return {
    changedField,
    additional,
    calculateAmount,
    calculateCurrencyAmount,
    calculateField,
  }
}

export default useCalculator
