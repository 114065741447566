import format from 'number-format.js'

import {
  CURRENCY_BNB,
  CURRENCY_BUSD,
  CURRENCY_DAI,
  CURRENCY_DCGT,
  CURRENCY_ETH,
  CURRENCY_TRX,
  CURRENCY_USCR,
  CURRENCY_USD,
  CURRENCY_USDC,
  CURRENCY_USDR,
  CURRENCY_USDT,
} from '../constants'

const asMoney = (value, currency = null, decimals = 4) => {
  const decimalSymbols = Array.apply(null, { length: decimals })
    .map(() => '#')
    .join('')

  switch (currency) {
    case CURRENCY_USD:
      return format(`$ ### ##0,${decimalSymbols}`, value)
    case CURRENCY_USDR:
      return format(`### ##0,${decimalSymbols} USDR`, value)
    case CURRENCY_USCR:
      return format(`### ##0,${decimalSymbols} U$CR`, value)
    case CURRENCY_DCGT:
      return format(`### ##0,${decimalSymbols} DCGT`, value)
    case CURRENCY_USDT:
      return format(`### ##0,${decimalSymbols} USDT`, value)
    case CURRENCY_TRX:
      return format(`### ##0,${decimalSymbols} TRX`, value)
    case CURRENCY_BUSD:
      return format(`### ##0,${decimalSymbols} BUSD`, value)
    case CURRENCY_BNB:
      return format(`### ##0,${decimalSymbols} BNB`, value)
    case CURRENCY_USDC:
      return format(`### ##0,${decimalSymbols} USDC`, value)
    case CURRENCY_DAI:
      return format(`### ##0,${decimalSymbols} DAI`, value)
    case CURRENCY_ETH:
      return format(`### ##0,${decimalSymbols} ETH`, value)
    case null:
      return format(`### ##0,${decimalSymbols}`, value)
  }

  return value
}

const asNumber = (value, decimals = 4) => {
  if (isNaN(value)) {
    return 0
  }

  return asMoney(value, null, decimals)
}

const asUSD = (value, decimals = 4) => {
  return asMoney(value, CURRENCY_USD, decimals)
}

const asUSCR = (value, decimals = 2) => {
  return asMoney(value, CURRENCY_USCR, decimals)
}

const asDCGT = (value, decimals = 4) => {
  return asMoney(value, CURRENCY_DCGT, decimals)
}

export { asMoney, asNumber, asUSD, asUSCR, asDCGT }
