import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

import { getTranslations } from './shared/api/translation'
import { LOCALSTORAGE_KEY } from './shared/constants'
import { getClientLanguage } from './shared/utils/ui'

const lang = JSON.parse(localStorage.getItem(LOCALSTORAGE_KEY))?.language ?? getClientLanguage()

i18n
  .use(initReactI18next)
  .use(Backend)
  .init({
    backend: {
      loadPath: '{{lng}}',
      request: async (options, url, payload, callback) => {
        try {
          const data = await getTranslations(false)

          callback(null, {
            status: 200,
            data: JSON.stringify(data[url]),
          })
        } catch (e) {
          callback(e)
        }
      },
    },
    fallbackLng: lang,
    keySeparator: ':',
    react: {
      useSuspense: true,
    },
  })

export default i18n
