import { get, post } from '../fetch'

export const getProfile = () => {
  return get('profile', true)
}

export const updateLanguage = (id = 'en') => {
  return post(
    'profile/set-language',
    {
      language: id,
    },
    true,
  )
}

export const receiveAuthKey = () => {
  return get(`profile/auth-key`, true)
}
