import React from 'react'
import { useTranslation } from 'react-i18next'
import { Element } from 'react-scroll'

const RoadMapSection = () => {
  const { t } = useTranslation()

  return (
    <Element name={'road-section'}>
      <section className="section section-roadmap">
        <div className="section-container">
          <div className="section-content">
            <div className="roadmap">
              <div className="roadmap--title">{t('Дорожная карта')}</div>

              <ul className="roadmap-list">
                <li className="roadmap-list--item">
                  <div className="roadmap-list--item-date">09.2022</div>
                  <div className="roadmap-list--item-title">{t('Внедрение блокчейна Binance Smart Chain (BSC)')}</div>
                  <div className="roadmap-list--item-description">
                    {t(
                      'Выпуск токенов недвижимости на блокчейне Binance Smart Chain (BSC). Использование для покупки стейблкойн USDT (TRC20).',
                    )}
                  </div>
                </li>
                <li className="roadmap-list--item">
                  <div className="roadmap-list--item-date">12.2022</div>
                  <div className="roadmap-list--item-title">{t('Покупка токенов за криптовалюту')}</div>
                  <div className="roadmap-list--item-description">
                    {t(
                      'Использование  криптовалюты TRX и BNB для покупки токенов. Добавление основных стейблкоинов BSC: USDT (BIP20) и BUSD.',
                    )}
                    <br />
                    {t('Реализация первого блокчейн-моста (bridge).')}
                  </div>
                </li>
                <li className="roadmap-list--item">
                  <div className="roadmap-list--item-date">08.2023</div>
                  <div className="roadmap-list--item-title">
                    {t('Выпуск токена платформы.')}
                    <br />
                    {t('Запуск системы Staking')}
                  </div>
                  <div className="roadmap-list--item-description">
                    {t(
                      'Добавление блокчейна Ethereum и криптовалюты ETH, USDT (ERC20), USDC и DAI. Разработка системы Staking для обеспечения пассивного дохода и распределения токена CoinRealtor (CR).',
                    )}
                  </div>
                </li>
                <li className="roadmap-list--item">
                  <div className="roadmap-list--item-date">10.2023</div>
                  <div className="roadmap-list--item-title">{t('Распределение токена платформы')}</div>
                  <div className="roadmap-list--item-description">
                    {t(
                      'Децентрализованное распределение токена платформы CoinRealtor (CR). Запуск внутренней Биржи. Наделение токена CR новым функционалом: получение дивидендов от деятельности платформы, распределение уникальных предложений для крупных держателей токена и т.д.',
                    )}
                  </div>
                </li>
                <li className="roadmap-list--item">
                  <div className="roadmap-list--item-date">05.2024</div>
                  <div className="roadmap-list--item-title">{t('Создание мостов и листинг токенов на DEX биржах')}</div>
                  <div className="roadmap-list--item-description">
                    {t(
                      'Запуск блокчейн-мостов (bridge) для трансфера токена из одного блокчейна в другой. Листинг на крупных DEX биржах  подключенных блокчейнов.',
                    )}
                  </div>
                </li>
                <li className="roadmap-list--item">
                  <div className="roadmap-list--item-date">08.2024</div>
                  <div className="roadmap-list--item-title">{t('Ввод стратегий управления недвижимостью')}</div>
                  <div className="roadmap-list--item-description">
                    {t(
                      'Введение различных стратегий участия в управлении недвижимостью: распределение дохода после реализации недвижимости, распределение прибыли при сдаче в аренду недвижимости, обмен и выкуп недвижимости за токены данной недвижимости.',
                    )}
                  </div>
                </li>

                <li className="roadmap-list--item">
                  <div className="roadmap-list--item-date">02.2025</div>
                  <div className="roadmap-list--item-title">{t('Внедрение системы обратного выкупа')}</div>
                  <div className="roadmap-list--item-description">
                    {t(
                      'Разработка системы “обратного выкупа” токенов для получения дохода от токенов цифровой недвижимости.',
                    )}
                    <br />
                    {t('Реализация покупки токенов сразу в трех блокчейнах: BSC, Ethereum и Tron.')}
                  </div>
                </li>
                <li className="roadmap-list--item">
                  <div className="roadmap-list--item-date">07.2025</div>
                  <div className="roadmap-list--item-title">{t('Запуск токенизации виртуальной недвижимости')}</div>
                  <div className="roadmap-list--item-description">
                    {t(
                      'Возможность токенизации виртуальной недвижимости и виртуальных участков в различных метавселенных.',
                    )}
                    <br />
                    {t('Выпуск и продажа токенов NFT виртуальной недвижимости.')}
                  </div>
                </li>
                <li className="roadmap-list--item">
                  <div className="roadmap-list--item-date">01.2026</div>
                  <div className="roadmap-list--item-title">{t('Переход CoinRealtor к принципам DAO')}</div>
                  <div className="roadmap-list--item-description">
                    {t(
                      'Переход платформы по токенизации недвижимости в DAO, голосование за метод управления по каждому объекту недвижимости, выбор метода распределения прибыли, определение общей стратегии развития платформы.',
                    )}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </Element>
  )
}

export default RoadMapSection
