import uniqBy from 'lodash/uniqBy'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import useSetState from 'react-use/lib/useSetState'

import useUserStore from 'shared/stores/user'

import { getNews } from 'shared/api/news'

const useNews = (perpage = 8) => {
  const { i18n } = useTranslation()
  const user = useUserStore()
  const language = user.language ?? i18n.language

  const [data, setData] = useSetState({
    data: [],
    count: 0,
    page: 1,
    loading: false,
  })

  const fetchList = (page = 1, size = 8, isLoadMore = true) => {
    setData({ loading: true })
    getNews(language === 'vi' ? 'vn' : language, page, size)
      .then((res) => {
        setData({
          data: isLoadMore ? uniqBy([...data.data, ...res.data], 'slug') : res.data,
          page: res.page,
          count: res.count,
          loading: false,
        })
      })
      .catch((e) => console.log(e))
      .finally(() => setData({ loading: false }))
  }

  useEffect(() => {
    fetchList(1, perpage, '')
  }, [])

  useEffect(() => {
    fetchList(1, perpage, '')
  }, [language])

  return {
    ...data,
    fetchList,
  }
}

export default useNews
