import { DateTime } from 'luxon'

export const DEFAULT_PARSE_TIMEZONE = 'utc'
export const DEFAULT_PARSE_PATTERN = 'yyyy-MM-dd HH:mm:ss'
export const DEFAULT_PATTERN = 'dd.MM.yyyy HH:mm'
export const DEFAULT_TIMEZONE = 'Europe/Moscow'
export const DEFAULT_LANGUAGE = 'en'

export const parse = (
  dateString,
  pattern = DEFAULT_PARSE_PATTERN,
  tz = DEFAULT_PARSE_TIMEZONE,
  locale = DEFAULT_LANGUAGE,
) => {
  return DateTime.fromFormat(dateString, pattern, { zone: tz, locale })
}

export const parseAndFormat = (
  dateString,
  pattern = DEFAULT_PATTERN,
  tz = DEFAULT_TIMEZONE,
  parseOpts = {
    pattern: DEFAULT_PARSE_PATTERN,
    tz: DEFAULT_PARSE_TIMEZONE,
  },
  locale = DEFAULT_LANGUAGE,
) => {
  return parse(dateString, parseOpts.pattern, parseOpts.tz, locale).setZone(tz).toFormat(pattern)
}

export const getNowDate = (format = DEFAULT_PATTERN) => {
  return DateTime.fromJSDate(new Date()).setZone(DEFAULT_PARSE_TIMEZONE).toFormat(format)
}

export const diffFromNow = (date) => {
  const end = parse(date, DEFAULT_PATTERN)
  const start = DateTime.fromJSDate(new Date()).setZone(DEFAULT_PARSE_TIMEZONE)

  return start.diff(end, ['hours', 'minutes']).toObject()
}
