import { get } from '../fetch'

const getInvoice = (id, authKey = null) => {
  return get(`finance/invoice?id=${id}${authKey ? `&authKey=${authKey}` : ''}`, authKey === null)
}

const cancelInvoice = (id, authKey = null) => {
  return get(`finance/invoice/${id}/cancel${authKey ? `?authKey=${authKey}` : ''}`, authKey === null)
}

const checkInvoicePayment = (id, authKey = null) => {
  return get(`finance/invoice/${id}/check-payment${authKey ? `?authKey=${authKey}` : ''}`, authKey === null)
}
const getCurrencyFees = () => {
  return get(`finance/currency-fees`)
}

export { getInvoice, cancelInvoice, checkInvoicePayment, getCurrencyFees }
