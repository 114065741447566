import { clear as clearLocalStorage, get as getItem, set as setItem } from 'local-storage'
import { LOCALSTORAGE_KEY } from 'shared/constants'
import create from 'zustand'

import { isGuest } from 'shared/utils/user'

import { getProfile, updateLanguage } from 'shared/api/profile'

export const VIEW_MODE_MOBILE = 'mobile'
export const VIEW_MODE_DESKTOP = 'desktop'

const defaultState = {
  accessToken: null,
  refreshToken: null,
  login: null,
  email: null,
  referrer: { login: null },
  referralLink: null,
  language: null,
  status: null,
  profile: {
    firstName: null,
    lastName: null,
    country: null,
    avatar: null,
  },
  balances: {},
  viewMode: null,
  ref: null,
}

const initState = getItem(LOCALSTORAGE_KEY) || defaultState

const useStore = create((set) => ({
  ...initState,
  update: (data) => set(() => ({ ...data })),
  receiveProfile: () =>
    set(async (state) => {
      if (isGuest(state)) {
        return state
      }

      try {
        const data = await getProfile()

        return state.update(data)
      } catch (err) {
        return state
      }
    }),
  setAvatar: (url) => set((state) => ({ ...state, profile: { ...state.profile, avatar: url } })),
  setLanguage: (language) =>
    set((state) => {
      if (!isGuest(state)) {
        updateLanguage(language).finally()
      }

      return { language }
    }),
  logout: () =>
    set(() => {
      clearLocalStorage()
      return defaultState
    }),
}))

useStore.subscribe((state) => setItem(LOCALSTORAGE_KEY, state))

export default useStore
