import { Splide, SplideSlide } from '@splidejs/react-splide'
import React from 'react'

const objectSliderConfig = {
  type: 'loop',
  interval: 5000,
  speed: 1500,
  arrows: false,
}
const IntroSection = () => {
  return (
    <section className="section section-object-intro">
      <div className="section-container">
        <div className="section-content">
          <Splide options={objectSliderConfig}>
            <SplideSlide>
              <img src="/uploads/content/slider1-1.jpg" alt="" className="object-hero--image" />
            </SplideSlide>
            <SplideSlide>
              <img src="/uploads/content/slider1-2.jpg" alt="" className="object-hero--image" />
            </SplideSlide>
            <SplideSlide>
              <img src="/uploads/content/slider1-3.jpg" alt="" className="object-hero--image" />
            </SplideSlide>
            <SplideSlide>
              <img src="/uploads/content/slider1-4.jpg" alt="" className="object-hero--image" />
            </SplideSlide>
          </Splide>
        </div>
      </div>
    </section>
  )
}

export default IntroSection
