import React from 'react'
import { useTranslation } from 'react-i18next'

const WhySection = () => {
  const { t } = useTranslation()

  return (
    <section className="section section-why">
      <div className="section-container">
        <div className="section-content">
          <div className="home-why">
            <div className="home-why--content">
              <div className="home-why--title">{t('Почему Дубай?')}</div>

              <div className="home-why--description">
                <ul className="home-why-list">
                  <li className="home-why-list--item">
                    <div className="home-why-list--item-icon">
                      <img src="./images/why-1.svg" alt="" />
                    </div>
                    <div className="home-why-list--item-content">
                      {t(
                        'Дубай – столица блокчейн-технологий и инвестиционных возможностей, которые включают покупку недвижимости и открытие собственного бизнеса.',
                      )}
                    </div>
                  </li>
                  <li className="home-why-list--item">
                    <div className="home-why-list--item-icon">
                      <img src="./images/why-2.svg" alt="" />
                    </div>
                    <div className="home-why-list--item-content">
                      {t(
                        'В 2022-2025 годах предвещают бурный рост стоимости жилой и коммерческой недвижимости, что влечет за собой возможности получить большую прибыль от аренды объектов и реализации недвижимости.',
                      )}
                    </div>
                  </li>
                  <li className="home-why-list--item">
                    <div className="home-why-list--item-icon">
                      <img src="./images/why-3.svg" alt="" />
                    </div>
                    <div className="home-why-list--item-content">
                      {t(
                        'Сегодня рынок недвижимости Дубай доступен, как никогда ранее. Особенно большим спросом пользуется недвижимость премиум-класса. Инвесторы стараются диверсифицировать активы в различные типы собственности, с разным местоположением.',
                      )}
                    </div>
                  </li>
                  <li className="home-why-list--item">
                    <div className="home-why-list--item-icon">
                      <img src="./images/why-4.svg" alt="" />
                    </div>
                    <div className="home-why-list--item-content">
                      {t(
                        'У объектов для токенизации выгодное географическое расположение и быстро развивающийся сегмент коммерции и технологий.',
                      )}
                    </div>
                  </li>
                  <li className="home-why-list--item">
                    <div className="home-why-list--item-icon">
                      <img src="./images/why-5.svg" alt="" />
                    </div>
                    <div className="home-why-list--item-content">
                      {t(
                        'Согласно планам развития, в ближайшие 20 лет Дубаи станет лучшим местом для жизни. Достаточно амбициозные цели являются ключом к привлечению потока туристов, роста экономики и инфраструктуры, а также укреплению социальной составляющей.',
                      )}
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="home-why--media">
              <img src="./uploads/content/home-why.jpg" alt="" className="home-why--image" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WhySection
