import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useMount } from 'react-use'
import { CURRENCY_DCGT } from 'shared/constants'

import useMedia from 'shared/hooks/useMedia'
import useTokenDetail from 'shared/hooks/useTokenDetail'

import { asUSD } from 'shared/utils/money'

export default function PurchasePopup() {
  const { t } = useTranslation()
  const { isMobile } = useMedia()
  const { tokenDetails } = useTokenDetail(CURRENCY_DCGT)
  const wrapperRef = useRef(null)
  const [isActive, setIsActive] = useState(false)
  const [isFull, setIsFull] = useState(false)

  useMount(() => {
    if (wrapperRef.current) {
      setTimeout(function () {
        setIsActive(true)
      }, 5000)
    }
    window.addEventListener('scroll', handleScroll, {
      passive: true,
    })
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  })
  const handleScroll = () => {
    const isBottom = Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight

    setIsActive(!isBottom)
  }
  const handleClickHeader = (e) => {
    if (isMobile) {
      e.preventDefault()

      setIsFull(!isFull)
    }
  }

  return (
    <div className="popup-container">
      <div
        className={`popup ${isActive && !isMobile ? '' : 'popup_hidden'} ${isFull ? 'popup_full' : ''}`}
        ref={wrapperRef}
      >
        <div className="popup--header" onClick={handleClickHeader}>
          <div className="currency-card currency-card_title">
            <div className="currency-card--image">
              <img src="./uploads/coin-dcgt-v2.svg" alt="DCGT" />
            </div>
            <div className="currency-card--content">
              <div className="rate rate_lg">
                <div className="rate--content">
                  <div className="rate--title">DCGT/USDT</div>
                  <div className="rate--value">{asUSD(tokenDetails.data?.rate ?? 0)}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="popup--content">
          <div className="popup--description">{t('Успей купить токен по текущей цене')}</div>

          <div className="popup--buttons">
            <Link to={`/purchase/${CURRENCY_DCGT}`} className="btn btn-primary">
              {t('Купить')}{' '}
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
