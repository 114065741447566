import React from 'react'
import { useTranslation } from 'react-i18next'
import { Element } from 'react-scroll'

import TokenCard from 'shared/components/TokenCard'

const TokenSection = () => {
  const { t } = useTranslation()

  return (
    <Element className="section section-home-object" name={'buy-section'}>
      <div className="section-container">
        <div className="section-content">
          <div className="section--header">
            <div className="section--title">{t('Токенизированные объекты')}</div>
          </div>

          <div className="objects">
            <TokenCard />
          </div>

          <div className="object-soon">
            <div className="object-soon--title">{t('Не пропустите! Скоро продажа нового токена')}</div>
          </div>
        </div>
      </div>
    </Element>
  )
}

export default TokenSection
