import React from 'react'
import { useTranslation } from 'react-i18next'

import useNews from 'shared/hooks/useNews'

import NewsCard from 'shared/components/NewsCard'

const NewsSection = () => {
  const { t } = useTranslation()
  const { data, loading } = useNews()

  if (!data.length) {
    return null
  }
  return (
    <div className="home-news">
      <div className="home-news--title">{t('Новости')}</div>

      <div className="home-news--content">
        <ul className={`home-news-list ${loading ? 'loading' : ''}`}>
          {data.map((el) => (
            <NewsCard key={el.slug} data={el} />
          ))}
        </ul>
      </div>
    </div>
  )
}

export default NewsSection
