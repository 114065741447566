import React from 'react'
import { useLocation } from 'react-router-dom'
import { useMount } from 'react-use'

import useFeesStore from 'shared/stores/fees'

import CookieAccept from './CookieAccept'
import Footer from './Footer'
import Header from './Header'
import PurchasePopup from './PurchasePopup'
import RoutesRenderer from './RoutesRenderer'
import ScrollToTop from './ScrollToTop'

const Main = () => {
  const location = useLocation()
  const fees = useFeesStore()

  useMount(() => {
    fees.receiveFees()
  })

  return (
    <div className="main">
      <ScrollToTop />
      <Header />

      <div className="main-container">
        <div className="main-content">
          <RoutesRenderer />
        </div>
        <Footer />
      </div>

      <svg width="0" height="0" className="hidden">
        <symbol id="caret" viewBox="0 0 8 6" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.565 1.466a.682.682 0 0 0-.997-.932L4 3.286 1.432.534a.682.682 0 0 0-.997.932L4 5.286l3.565-3.82Z"
            fill="currentColor"
          />
        </symbol>

        <symbol id="icon-bookmark" width="14" height="18" viewBox="0 0 14 18" xmlns="http://www.w3.org/2000/svg">
          <path
            d="m6.97 13.8-.236-.1-.236.1-5.862 2.513v-14.4A1.314 1.314 0 0 1 1.95.6h9.568c.723 0 1.314.585 1.314 1.314v14.399L6.97 13.8Z"
            stroke="currentColor"
            strokeWidth="1.2"
          />
        </symbol>

        <symbol id="icon-heart" width="20" height="18" viewBox="0 0 20 18" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M8.585 15.85h-.001c-2.476-2.245-4.482-4.068-5.877-5.774C1.32 8.378.6 6.869.6 5.262.6 2.639 2.646.6 5.262.6c1.484 0 2.918.694 3.85 1.78l.456.531.456-.53C10.957 1.294 12.39.6 13.873.6c2.616 0 4.662 2.038 4.662 4.662 0 1.607-.719 3.116-2.107 4.814-1.394 1.706-3.4 3.53-5.876 5.774h-.001l-.984.896-.983-.895Z"
            stroke="currentColor"
            strokeWidth="1.2"
          />
        </symbol>

        <symbol id="icon-share" width="18" height="17" viewBox="0 0 18 17" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4.062 12.175a3.71 3.71 0 0 0 2.947-1.472l3.662 1.974a3.3 3.3 0 0 0-.13.873 3.363 3.363 0 0 0 4.394 3.215 3.36 3.36 0 1 0-3.766-5.156L7.565 9.667c.297-.79.297-1.66 0-2.449l3.608-1.943a3.326 3.326 0 0 0 5.863-2.972 3.327 3.327 0 0 0-6.491 1.031c.004.296.048.59.13.874L7.009 6.182a3.722 3.722 0 0 0-6.295.622 3.727 3.727 0 0 0 3.348 5.37Zm9.817-.786a2.16 2.16 0 0 1 1.527 3.69 2.16 2.16 0 1 1-3.054-3.056 2.16 2.16 0 0 1 1.527-.634Zm0-10.215a2.159 2.159 0 0 1 2.16 2.16 2.161 2.161 0 0 1-3.688 1.529 2.162 2.162 0 0 1 1.528-3.69ZM4.062 5.889a2.551 2.551 0 0 1 2.552 2.553 2.554 2.554 0 0 1-2.552 2.554 2.551 2.551 0 0 1-2.553-2.554A2.557 2.557 0 0 1 4.062 5.89Z"
            fill="currentColor"
          />
        </symbol>

        <symbol id="icon-reverse" width="13" height="8" viewBox="0 0 13 8" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M2.828 3.429 0 5.714 2.828 8V6.286h4.969V5.143H2.828V3.429Zm9.93-1.143L9.93 0v1.714H4.962v1.143H9.93v1.714l2.829-2.285Z"
            fill="currentColor"
          />
        </symbol>

        <symbol id="social-facebook" viewBox="0 0 12 24" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12 0v4.8H9.6c-.828 0-1.2.972-1.2 1.8v3H12v4.8H8.4V24H3.6v-9.6H0V9.6h3.6V4.8A4.8 4.8 0 0 1 8.4 0H12Z"
            fill="currentColor"
          />
        </symbol>

        <symbol id="social-instagram" viewBox="0 0 23 24" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M6.67 0h9.66C20.01 0 23 3.12 23 6.96v10.08a7.117 7.117 0 0 1-1.954 4.922C19.796 23.267 18.1 24 16.33 24H6.67C2.99 24 0 20.88 0 17.04V6.96c0-1.846.703-3.616 1.954-4.921C3.204.733 4.9 0 6.67 0Zm-.23 2.4a4.054 4.054 0 0 0-2.927 1.265A4.417 4.417 0 0 0 2.3 6.72v10.56c0 2.388 1.852 4.32 4.14 4.32h10.12a4.054 4.054 0 0 0 2.927-1.265A4.417 4.417 0 0 0 20.7 17.28V6.72c0-2.388-1.851-4.32-4.14-4.32H6.44Zm11.098 1.8c.38 0 .746.158 1.016.44.27.28.421.662.421 1.06s-.151.78-.421 1.06c-.27.282-.635.44-1.016.44-.382 0-.747-.158-1.017-.44A1.534 1.534 0 0 1 16.1 5.7c0-.398.151-.78.421-1.06.27-.282.635-.44 1.017-.44ZM11.5 6c1.525 0 2.988.632 4.066 1.757A6.135 6.135 0 0 1 17.25 12a6.135 6.135 0 0 1-1.684 4.243A5.631 5.631 0 0 1 11.5 18a5.63 5.63 0 0 1-4.066-1.757A6.135 6.135 0 0 1 5.75 12c0-1.591.606-3.117 1.684-4.243A5.631 5.631 0 0 1 11.5 6Zm0 2.4c-.915 0-1.793.38-2.44 1.054A3.68 3.68 0 0 0 8.05 12c0 .955.363 1.87 1.01 2.546A3.379 3.379 0 0 0 11.5 15.6c.915 0 1.793-.38 2.44-1.054A3.68 3.68 0 0 0 14.95 12a3.68 3.68 0 0 0-1.01-2.546A3.379 3.379 0 0 0 11.5 8.4Z"
            fill="currentColor"
          />
        </symbol>

        <symbol id="social-telegram" viewBox="0 0 29 24" xmlns="http://www.w3.org/2000/svg">
          <path
            d="m10.76 22.548.444-6.712L23.391 4.854c.54-.492-.11-.73-.825-.301L7.523 14.058l-6.507-2.063c-1.396-.396-1.412-1.364.318-2.063L26.676.157c1.159-.523 2.27.286 1.825 2.063l-4.316 20.328c-.302 1.444-1.174 1.793-2.38 1.127l-6.57-4.856-3.158 3.063c-.365.364-.667.666-1.317.666Z"
            fill="currentColor"
          />
        </symbol>

        <symbol id="social-twitter" viewBox="0 0 30 24" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M30 2.824a12.436 12.436 0 0 1-3.528.974 6.086 6.086 0 0 0 2.696-3.36 12.51 12.51 0 0 1-3.9 1.482C24.135.706 22.543 0 20.736 0c-3.37 0-6.123 2.71-6.123 6.056 0 .48.057.946.158 1.384A17.58 17.58 0 0 1 2.094 1.115a5.921 5.921 0 0 0-.832 3.036A5.936 5.936 0 0 0 4 9.176c-1.018 0-1.965-.282-2.796-.705v.042c0 2.936 2.122 5.393 4.933 5.944a6.146 6.146 0 0 1-2.768.098 6.126 6.126 0 0 0 5.736 4.207C7.012 20.4 4.36 21.36 1.463 21.36c-.488 0-.975-.028-1.463-.085A17.616 17.616 0 0 0 9.436 24c11.3 0 17.51-9.233 17.51-17.238 0-.268 0-.522-.015-.79A12.186 12.186 0 0 0 30 2.824Z"
            fill="currentColor"
          />
        </symbol>

        <symbol id="social-youtube" viewBox="0 0 35 24">
          <path
            d="M33.3541 3.74734C32.9624 2.27253 31.8084 1.11001 30.3421 0.715401C27.6863 2.38419e-07 17.0334 0 17.0334 0C17.0334 0 6.38041 2.38419e-07 3.72321 0.715401C2.25834 1.11001 1.10433 2.27111 0.712562 3.74734C0 6.42157 0 12 0 12C0 12 0 17.5784 0.712562 20.2527C1.10433 21.7275 2.25834 22.89 3.72463 23.2846C6.38041 24 17.0334 24 17.0334 24C17.0334 24 27.6863 24 30.3435 23.2846C31.8084 22.89 32.9624 21.7289 33.3556 20.2527C34.0667 17.5784 34.0667 12 34.0667 12C34.0667 12 34.0667 6.42157 33.3541 3.74734ZM13.55 17.066V6.934L22.4528 12L13.55 17.066Z"
            fill="#EAEFFF"
          />
        </symbol>

        <symbol id="qr" viewBox="0 0 28 22" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M3.81 8.024V1h7.023M1 10.833h25.286M3.81 13.398v7.269h7.023M16.453 1h7.023v7.024m0 5.619v7.024h-7.024"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </symbol>

        <symbol id="copy" viewBox="0 0 24 26" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M14.461 0H3.385C2.487 0 1.627.351.992.975A3.3 3.3 0 0 0 0 3.325v13.13a3.3 3.3 0 0 0 .992 2.35 3.418 3.418 0 0 0 2.393.976h2.621v2.894c0 .881.358 1.727.992 2.35A3.418 3.418 0 0 0 9.391 26h11.224c.898 0 1.758-.351 2.393-.975.634-.623.991-1.469.992-2.35V9.372c0-.882-.358-1.727-.992-2.35a3.418 3.418 0 0 0-2.393-.976h-2.769v-2.72a3.3 3.3 0 0 0-.992-2.351A3.419 3.419 0 0 0 14.462 0ZM1.846 16.455V3.326c0-.4.163-.785.451-1.068.289-.283.68-.443 1.088-.444H14.46c.408.001.8.16 1.088.444.288.283.45.667.45 1.068v13.13c0 .4-.162.784-.45 1.067-.289.284-.68.443-1.088.444H3.385c-.408 0-.8-.16-1.088-.444a1.501 1.501 0 0 1-.451-1.068Zm18.769-8.594c.408 0 .799.16 1.087.443.288.284.45.668.451 1.069v13.302c0 .4-.162.785-.45 1.068-.29.283-.68.443-1.088.444H9.39c-.408 0-.799-.16-1.087-.443a1.5 1.5 0 0 1-.451-1.069v-2.894h6.61c.896 0 1.757-.351 2.391-.975a3.3 3.3 0 0 0 .993-2.35V7.86h2.769Z"
            fill="currentColor"
          />
        </symbol>
        <symbol id="sandclock" viewBox="0 0 16 22" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M15 20h-1v-1.667a4.998 4.998 0 0 0-.3-1.678.958.958 0 0 0-.035-.084A4.976 4.976 0 0 0 13 15.334l-1.4-1.867a3.02 3.02 0 0 1-.6-1.801v-1.01a3.021 3.021 0 0 1 .878-2.12l.658-.658a4.946 4.946 0 0 0 1.396-2.838c0-.014.008-.026.008-.04l-.003-.014c.035-.213.056-.428.063-.643V2h1a1 1 0 1 0 0-2H1a1 1 0 0 0 0 2h1v2.343c.007.215.028.43.063.643L2.06 5c0 .014.007.026.008.04a4.946 4.946 0 0 0 1.396 2.838l.658.657c.56.564.876 1.326.878 2.121v1.01a3.022 3.022 0 0 1-.6 1.8L3 15.335a4.983 4.983 0 0 0-.665 1.237.968.968 0 0 0-.035.084 5.003 5.003 0 0 0-.3 1.678V20H1a1 1 0 0 0 0 2h14a1 1 0 0 0 0-2ZM4 4V2h8v2H4Zm.878 2.464A2.97 2.97 0 0 1 4.502 6h6.997a2.97 2.97 0 0 1-.377.464l-.658.657A4.96 4.96 0 0 0 9.042 10H6.957a4.96 4.96 0 0 0-1.421-2.879l-.658-.657ZM6 14.666c.58-.775.922-1.7.989-2.666H9.01c.067.965.41 1.89.989 2.666L11 16H5l1-1.334ZM12 20H4v-1.667c.003-.111.012-.223.027-.333h7.946c.015.11.024.222.027.333V20Z"
            fill="currentColor"
          />
          <path
            d="M15 20h-1v-1.667a4.998 4.998 0 0 0-.3-1.678.958.958 0 0 0-.035-.084A4.976 4.976 0 0 0 13 15.334l-1.4-1.867a3.02 3.02 0 0 1-.6-1.801v-1.01a3.021 3.021 0 0 1 .878-2.12l.658-.658a4.946 4.946 0 0 0 1.396-2.838c0-.014.008-.026.008-.04l-.003-.014c.035-.213.056-.428.063-.643V2h1a1 1 0 1 0 0-2H1a1 1 0 0 0 0 2h1v2.343c.007.215.028.43.063.643L2.06 5c0 .014.007.026.008.04a4.946 4.946 0 0 0 1.396 2.838l.658.657c.56.564.876 1.326.878 2.121v1.01a3.022 3.022 0 0 1-.6 1.8L3 15.335a4.983 4.983 0 0 0-.665 1.237.968.968 0 0 0-.035.084 5.003 5.003 0 0 0-.3 1.678V20H1a1 1 0 0 0 0 2h14a1 1 0 0 0 0-2ZM4 4V2h8v2H4Zm.878 2.464A2.97 2.97 0 0 1 4.502 6h6.997a2.97 2.97 0 0 1-.377.464l-.658.657A4.96 4.96 0 0 0 9.042 10H6.957a4.96 4.96 0 0 0-1.421-2.879l-.658-.657ZM6 14.666c.58-.775.922-1.7.989-2.666H9.01c.067.965.41 1.89.989 2.666L11 16H5l1-1.334ZM12 20H4v-1.667c.003-.111.012-.223.027-.333h7.946c.015.11.024.222.027.333V20Z"
            fill="currentColor"
          />
        </symbol>
      </svg>
      {location.pathname === '/' && <PurchasePopup />}

      <CookieAccept />
    </div>
  )
}

export default Main
