import React from 'react'

import AboutSection from './components/AboutSection'
import ContactSection from './components/ContactSection'
import CorpSection from './components/CorpSection'
import FeaturesSection from './components/FeaturesSection'
import IntroSection from './components/IntroSection'
import RoadMapSection from './components/RoadMapSection'
import TokenSection from './components/TokenSection'
import WhySection from './components/WhySection'

export default () => {
  return (
    <>
      <IntroSection />
      <AboutSection />
      <FeaturesSection />
      <TokenSection />
      <WhySection />
      <RoadMapSection />
      <CorpSection />
      <ContactSection />
    </>
  )
}
