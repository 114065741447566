import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useEffectOnce } from 'react-use'
import useInterval from 'react-use/lib/useInterval'
import useSearchParam from 'react-use/lib/useSearchParam'
import { LANDING_URL } from 'shared/constants'

import useTelegramLogin from 'shared/hooks/useTelegramLogin'

import useUserStore from 'shared/stores/user'

import { isGuest } from 'shared/utils/user'

import { login } from 'shared/api/auth/telegram-passport'
import { getProfile } from 'shared/api/profile'

import useStepsStore from '../stores/steps'
import { getStepData } from '../utils/steps'
import ResultAuth from './ResultAuth'
import Sponsor from './Sponsor'

const Start = ({ user, loading = false, fail = false, onLogin }) => {
  const { t } = useTranslation()

  return (
    <>
      {fail && (
        <div className="buy-token-auth--info">
          <div className="action-message action-message_fail">
            <div className="action-message--content">
              <div className="action-message--title">{t('Авторизация отклонена!')}</div>
              <div className="action-message--title">{t('Попробуйте снова')}</div>
            </div>
          </div>
        </div>
      )}

      <div className="buy-token-auth--info">
        <div className="telegram-info">
          <Trans
            i18nKey={
              'Для входа в личный кабинет вам необходимо иметь установленное приложение <a>Telegram</a> на вашем устройстве.'
            }
            components={{
              a: <a href="https://telegram.org" target="_blank" />,
            }}
          />
          <br />
          <Trans
            i18nKey={'Если у вас возникли проблемы, <a>напишите нам</a> об этом.'}
            components={{
              a: <a href="/#contact-section" />,
            }}
          />
        </div>
      </div>

      <div className="buy-token-auth--sponsor">
        <Sponsor user={user} />
      </div>

      <div className="buy-token-auth--buttons">
        <button className="btn" onClick={onLogin} disabled={loading}>
          <svg>
            <use xlinkHref="#social-telegram" />
          </svg>
          <span>{t('Войти')}</span>
        </button>
      </div>
    </>
  )
}

const Waiting = ({ onLogin, onFail }) => {
  const { t } = useTranslation()
  const maxAttempts = 5
  const seconds = 10
  const [updated, toggleUpdated] = useState(true)
  const [attempts, setAttempts] = useState(0)

  useInterval(
    () => {
      onLogin().then((data) => {
        if (data) {
          toggleUpdated(false)
        }
      })
      setAttempts(attempts + 1)
    },
    updated ? 5000 : null,
  )

  useEffect(() => {
    if (attempts > maxAttempts) {
      onFail && onFail()
    }
  }, [attempts])

  return (
    <div className="auth auth_inline">
      <div className="auth-content">
        <div>
          <div>{t('Выполняется вход в систему...')}</div>
          <div>{t('Обычно это занимает не более {{count}} секунд.', { count: 30 })}</div>
        </div>
      </div>
      <div className="auth-count">
        <div className="auth-counter">
          <svg viewBox="0 0 160 160" style={{ '--auth-count': seconds + 's' }}>
            <circle cx="50%" cy="50%" r="75" className="circle2" />
            <circle cx="50%" cy="50%" r="75" className="circle1" />
          </svg>
          <div className="auth-counter--value">
            <svg className="sandclock-svg">
              <use xlinkHref="#sandclock" />
            </svg>
          </div>
        </div>
      </div>
    </div>
  )
}

const TelegramAuth = ({ token, paymentLoading = false, onCreatePayment, onCancelPayment }) => {
  const stepNum = 2
  const user = useUserStore()
  const { steps, updateStep } = useStepsStore()

  const { i18n } = useTranslation()
  const language = user.language || i18n.language
  const { loading, login: telegramLogin } = useTelegramLogin(user.ref, language, `${LANDING_URL}/purchase/${token}`)

  const ssid = useSearchParam('ssid')
  const tgStatus = useSearchParam('tg_passport')
  const navigate = useNavigate()

  const step = getStepData(stepNum, steps)
  const isUserGuest = isGuest(user)

  useEffectOnce(() => {
    if (tgStatus === 'cancel') {
      updateStep(stepNum, { waiting: false, cancel: true })
    }
    if (tgStatus === 'success') {
      updateStep(stepNum, { waiting: true, cancel: false, ssid, tgStatus })
    }
  })

  const handleStartLogin = async (ev) => {
    ev.preventDefault()
    await telegramLogin()
  }

  const handleLogin = () => {
    return login({ ssid })
      .then((data) => {
        if (data) {
          user.update({ accessToken: data.token, refreshToken: data.refreshToken })

          getProfile()
            .then((profile) => {
              user.update({ ...profile, ref: null })
              updateStep(stepNum, { waiting: false, cancel: false, ssid: null, tgStatus: null })
              navigate(`/purchase/${token}`)
            })
            .catch((err) => console.log(err))
        }

        return data
      })
      .catch((err) => console.log(err))
  }

  const handleLoginTimeExpired = () => {
    updateStep(stepNum, { waiting: false, cancel: false, ssid: null, tgStatus: null })
    navigate(`/purchase/${token}`)
  }

  return (
    <>
      {isUserGuest ? (
        (() => {
          if (step.waiting) {
            return <Waiting onLogin={handleLogin} onFail={handleLoginTimeExpired} />
          }

          return <Start user={user} loading={loading} fail={step.cancel} onLogin={handleStartLogin} />
        })()
      ) : (
        <ResultAuth user={user} loading={paymentLoading} onCreate={onCreatePayment} onCancel={onCancelPayment} />
      )}
    </>
  )
}

export default TelegramAuth
