import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMount } from 'react-use'

import { getBlockchainIcon, getBlockchainLabel, getCurrencyDescription, getCurrencyIcon } from 'shared/utils/billing'
import { asMoney } from 'shared/utils/money'

import { purchaseCalculation } from 'shared/api/tokens'

const Order = ({ token, amount, blockchain, currency, currencyAmount, blockchainReceipt }) => {
  const { t } = useTranslation()
  const [tokenRate, setTokenRate] = useState()

  useMount(() => {
    purchaseCalculation(token, { amount: 1, currency: currency }, false)
      .then((data) => setTokenRate(data))
      .catch((err) => console.log(err))
  })

  return (
    <div className="buy-token--form">
      <div className="buy-token-method">
        <div className="buy-token-method--item">
          <div className="buy-token-method--item-title">{t('Способ оплаты')}:</div>
          <div className="buy-token-method--item-value">
            <div className="currency-card">
              <div className="currency-card--image">
                <img src={getCurrencyIcon(currency, blockchain)} alt={currency} />
              </div>
              <div className="currency-card--content">
                <div className="currency-card--title">{currency}</div>
                <div className="currency-card--subtitle">{getCurrencyDescription(currency, blockchain)}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="buy-token-method--item">
          <div className="buy-token-method--item-title">{t('К оплате')}:</div>
          <div className="buy-token-method--item-value">
            <div className="currency-value">
              <div className="currency-value--title">{asMoney(currencyAmount, currency)}</div>
              {tokenRate && (
                <div className="currency-value--subtitle">
                  {asMoney(1, token)} = {asMoney(tokenRate.amount, tokenRate.currency)}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="buy-token-method--item">
          <div className="buy-token-method--item-title">{t('Количество токенов')}:</div>
          <div className="buy-token-method--item-value">{asMoney(amount, token)}</div>
        </div>
        <div className="buy-token-method--item">
          <div className="buy-token-method--item-title">{t('Блокчейн:')}</div>
          <div className="buy-token-method--item-value">
            <div className="blockchain-card">
              <div className="blockchain-card--image">
                <img src={getBlockchainIcon(blockchainReceipt)} alt={getBlockchainLabel(blockchainReceipt)} />
              </div>
              <div className="blockchain-card--content">
                <div className="blockchain-card--title">{getBlockchainLabel(blockchainReceipt)}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Order
