import { useMount } from 'react-use'
import useSetState from 'react-use/lib/useSetState'

import { getToken, getTokenRates } from 'shared/api/tokens'

const useTokenDetail = (tokenName) => {
  const [tokenDetails, setTokenDetails] = useSetState({
    loading: false,
    data: {
      token: 'DCGT',
      changePercent: 0,
      rate: 0,
      nextRate: 0,
      total: 0,
      totalSold: 0,
      round: 0,
      roundSold: 0,
      coordinates: {
        lat: 25.20399342854445,
        lng: 55.34940543188134,
      },
    },
  })

  const [tokenRates, setTokenRates] = useSetState({
    loading: false,
    data: null,
  })

  useMount(() => {
    fetchToken()
    fetchTokenRates()
  })

  const fetchToken = () => {
    setTokenDetails({ loading: true })
    getToken(tokenName)
      .then((res) => {
        setTokenDetails({ data: res })
      })
      .finally(() => {
        setTokenDetails({ loading: false })
      })
  }

  const fetchTokenRates = () => {
    setTokenRates({ loading: true })

    getTokenRates(tokenName, 'week', true)
      .then((res) => {
        setTokenRates({ data: res })
      })
      .finally(() => {
        setTokenRates({ loading: false })
      })
  }

  return { tokenDetails, tokenRates }
}

export default useTokenDetail
