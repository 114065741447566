import React from 'react'

const Radio = ({ register = () => {}, id, name, label = null, value }) => {
  return (
    <div className="radio">
      <input {...register(name)} type="radio" name={name} id={id} value={value} />
      {label && (
        <label htmlFor={id}>
          <span className="input-button" />
          <span className="text">{label}</span>
        </label>
      )}
    </div>
  )
}

export default Radio
