import React from 'react'
import { useTranslation } from 'react-i18next'

const Error = ({ code = 404 }) => {
  const { t } = useTranslation()

  return (
    <div className="section section-error">
      <div className="section-container">
        <div className="section-content">
          <div className="error">
            <div className="error--code">{code}</div>
            <div className="error--title">{code === 404 ? t('Страница не найдена') : t('Что-то пошло не так')}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Error
