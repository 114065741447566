import { useState } from 'react'
import useMount from 'react-use/lib/useMount'

import { getSettings } from 'shared/api/settings'

const useSettings = () => {
  const [settings, setSettings] = useState({
    countries: {},
  })

  useMount(() => {
    getSettings()
      .then((data) => setSettings(data))
      .catch((err) => console.log(err))
  })

  return {
    countries: settings.countries,
  }
}

export default useSettings
