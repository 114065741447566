import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom'
import { Link, animateScroll as scroll, scroller } from 'react-scroll'
import { useClickAway } from 'react-use'

import useUserStore from 'shared/stores/user'

import { isGuest } from 'shared/utils/user'

import LanguageSelector from './LanguageSelector'
import UserMenu from './UserMenu'

export default () => {
  const user = useUserStore()
  const { t } = useTranslation()
  const menuRef = useRef(null)
  const btnRef = useRef(null)
  const location = useLocation()
  const isHome = useMemo(() => location.pathname === '/', [location.pathname])

  const [isOpenMenu, toggleMenu] = useState(false)
  const [activeSection, setActiveSection] = useState('')
  const navigate = useNavigate()

  const isUserGuest = isGuest(user)

  const scrollTarget = (target) =>
    setTimeout(() => {
      scroller.scrollTo(target, { smooth: true, offset: target === 'road-section' ? 0 : -50 })
    }, 500)

  useEffect(() => {
    let position = document.documentElement.scrollTop
    const header = document.querySelector('.header.odd')

    if (location.hash) {
      header.classList.remove('odd-active')
    }

    window.onscroll = function () {
      const header = document.querySelector('.header.odd')

      const scroll = document.documentElement.scrollTop

      if (isOpenMenu) {
        toggleMenu(false)
      }
      if (document.querySelector('.header.odd')) {
        // const header = document.querySelector('.header.odd')

        if (scroll > position) {
          if (position > 76) {
            header.classList.remove('odd-active')
          }
        } else {
          if (position < 76) {
            header.classList.add('odd-active')
          } else {
            header.classList.remove('odd-active')
          }
        }
      }

      position = scroll
    }
  }, [])

  useClickAway(menuRef, (e) => {
    if (isOpenMenu && !btnRef.current.contains(e.target)) {
      toggleMenu(false)
    }
  })

  const handleToggleMenu = (ev) => {
    ev.preventDefault()
    toggleMenu(!isOpenMenu)
  }

  const handleSetActive = (v) => {
    setActiveSection(v)
  }

  const onClickLink = async (target) => {
    if (location.pathname !== '/') {
      await navigate('/')
      scrollTarget(target)
    }
    if (isOpenMenu) {
      toggleMenu(false)
    }
  }

  return (
    <div id="header" className={`header ${isHome ? 'odd odd-active' : ''}`}>
      <div className="header-content">
        <a href="#" className={`menu-button ${isOpenMenu ? 'is-active' : ''}`} onClick={handleToggleMenu} ref={btnRef}>
          <span>
            <span>Меню</span>
          </span>
        </a>

        <div className="header-logo">
          <RouterLink
            to="/"
            className="header-logo--link"
            title="CoinRealtor"
            onClick={() => {
              scroll.scrollToTop()
            }}
          >
            CoinRealtor
          </RouterLink>
        </div>

        <div className={`header-menu ${isOpenMenu ? 'is-active' : ''}`} ref={menuRef}>
          <ul className="main-nav">
            <li className={`main-nav--item ${activeSection === 'about-section' ? 'is-active' : ''}`}>
              <Link
                href={'#'}
                className="main-nav--link"
                to="about-section"
                spy={true}
                hashSpy
                smooth={true}
                duration={500}
                onSetActive={handleSetActive}
                offset={-50}
                onClick={() => onClickLink('about-section')}
              >
                {t('О нас')}
              </Link>
            </li>
            <li className={`main-nav--item ${activeSection === 'adv-section' ? 'is-active' : ''}`}>
              <Link
                href={'#'}
                className="main-nav--link"
                to="adv-section"
                spy={true}
                smooth={true}
                duration={500}
                hashSpy
                offset={-50}
                onSetActive={handleSetActive}
                onClick={() => onClickLink('adv-section')}
              >
                {t('Преимущества')}
              </Link>
            </li>
            <li className={`main-nav--item ${activeSection === 'buy-section' ? 'is-active' : ''}`}>
              <Link
                href={'#'}
                className="main-nav--link"
                to="buy-section"
                spy={true}
                smooth={true}
                duration={500}
                hashSpy
                offset={-50}
                onSetActive={handleSetActive}
                onClick={() => onClickLink('buy-section')}
              >
                {t('Купить')}
              </Link>
            </li>
            <li className={`main-nav--item ${activeSection === 'road-section' ? 'is-active' : ''}`}>
              <Link
                href={'#'}
                className="main-nav--link"
                to="road-section"
                spy={true}
                smooth={true}
                hashSpy
                duration={500}
                onSetActive={handleSetActive}
                onClick={() => onClickLink('road-section')}
              >
                {t('Дорожная карта')}
              </Link>
            </li>
            <li className={`main-nav--item ${activeSection === 'contact-section' ? 'is-active' : ''}`}>
              <Link
                href={'#'}
                className="main-nav--link"
                to="contact-section"
                spy={true}
                smooth={true}
                hashSpy
                duration={500}
                onSetActive={handleSetActive}
                onClick={() => onClickLink('contact-section')}
              >
                {t('Контакты')}
              </Link>
            </li>
          </ul>
        </div>

        <LanguageSelector />

        <div className="header-usermenu">
          {isUserGuest ? (
            <RouterLink to="/auth" className="btn btn-primary btn-md">
              <svg>
                <use xlinkHref="#social-telegram" />
              </svg>
              <span>{t('Войти')}</span>
            </RouterLink>
          ) : (
            <UserMenu user={user} />
          )}
        </div>
      </div>
    </div>
  )
}
