import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { I18nextProvider } from 'react-i18next'
import { BrowserRouter } from 'react-router-dom'
import { useMount } from 'react-use'
import useSearchParam from 'react-use/lib/useSearchParam'

import useLoginConsole from 'shared/hooks/useLoginConsole'

import Loader from './components/Loader'
import Main from './components/Main'
import ModalsProvider from './components/ModalsProvider'
import i18n from './i18n'
import useUserStore from './shared/stores/user'

const Root = () => {
  const user = useUserStore()
  const ref = useSearchParam('ref')

  // only for development
  const { login } = useLoginConsole()

  // eslint-disable-next-line valid-typeof
  if (typeof window !== undefined) {
    window.l = login
  }

  useMount(() => {
    user.receiveProfile()

    if (ref && ref !== user.ref) {
      user.update({ ref })
    }
  })

  return (
    <ModalsProvider>
      <div id="app">
        <Main />
      </div>
    </ModalsProvider>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <Suspense fallback={<Loader />}>
    <I18nextProvider i18n={i18n}>
      <BrowserRouter>
        <Root />
      </BrowserRouter>
    </I18nextProvider>
  </Suspense>,
)
