import React from 'react'
import { Route, Routes } from 'react-router-dom'
import AuthScreen from 'screens/Auth'
import MainScreen from 'screens/Main'
import NewsDetail from 'screens/News/screens/NewsDetail'
import Purchase from 'screens/Purchase'
import TokenObject from 'screens/TokenObject'

import Error from 'components/Error'

const RoutesRenderer = () => {
  return (
    <Routes>
      <Route path={'*'} element={<Error />} />
      <Route exact path={'/'} element={<MainScreen />} />
      <Route path={'auth/*'} element={<AuthScreen />} />
      <Route path={'objects/:slug'} element={<TokenObject />} />
      <Route exact path={'news/:slug'} element={<NewsDetail />} />
      <Route exact path={'purchase/:token'} element={<Purchase />} />
      <Route exact path={'/error'} element={<Error code={500} />} />
    </Routes>
  )
}

export default RoutesRenderer
