import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMount } from 'react-use'
import {
  BLOCKCHAIN_BSC,
  BLOCKCHAIN_ETH,
  BLOCKCHAIN_TRX,
  CURRENCY_BASE,
  CURRENCY_BNB,
  CURRENCY_BUSD,
  CURRENCY_DAI,
  CURRENCY_ETH,
  CURRENCY_TRX,
  CURRENCY_USCR_NAME,
  CURRENCY_USDC,
  CURRENCY_USDT,
  MIN_AMOUNTS,
  PURCHASE_MIN_AMOUNT,
} from 'shared/constants'
import * as yup from 'yup'

import useFormWrapper from 'shared/hooks/useFormWrapper'

import useFeesStore from 'shared/stores/fees'
import useUserStore from 'shared/stores/user'

import {
  composeCurrency,
  exchangeFromBaseCurrency,
  getBlockchainIcon,
  getBlockchainLabel,
  getCurrencyDescription,
  getCurrencyIcon,
  recomposeCurrency,
  roundCurrencyAmount,
} from 'shared/utils/billing'
import { asMoney } from 'shared/utils/money'
import { roundDown } from 'shared/utils/number'
import { getBalance, isGuest } from 'shared/utils/user'

import { getRates } from 'shared/api/currency-rate'
import { getToken } from 'shared/api/tokens'

import Dropdown from 'shared/components/forms/Dropdown'
import FormField from 'shared/components/forms/FormField'
import Input from 'shared/components/forms/Input'

import useCalculator from '../hooks/useCalculator'
import useStepsStore from '../stores/steps'
import { getStepData } from '../utils/steps'

const MIN_AMOUNT = 10 // BASE_CURRENCY
const MAX_AMOUNT = 100_000

const Payment = ({ token }) => {
  const user = useUserStore()
  const fees = useFeesStore()
  const { steps, updateStep, setActiveStep } = useStepsStore()
  const [rate, setRate] = useState(0)
  const [rateDiscount, setRateDiscount] = useState({
    isActive: false,
    percent: 0,
  })
  const [currencyRates, setCurrencyRates] = useState({})
  const { t } = useTranslation()

  const [initial, setInitial] = useState(false)
  const stepData = getStepData(1, steps)
  const [lastCurrency, setLastCurrency] = useState(stepData.currency || CURRENCY_USDT)

  const validationSchema = yup.object().shape({
    amount: yup
      .number()
      .min(1, `${t('Минимальное количество')} ${asMoney(1, token)}`)
      .typeError(t('Значение должно быть числом')),
    currencyAmount: yup
      .number()
      .typeError(t('Значение должно быть числом'))
      .test({
        name: 'min',
        params: { currency: lastCurrency },
        message: ({ currency }) => {
          const { currency: preparedCurrency } = recomposeCurrency(currency)
          const minValue = MIN_AMOUNTS[preparedCurrency] ?? MIN_AMOUNT

          const minAmount = exchangeFromBaseCurrency(minValue, preparedCurrency, currencyRates)

          return `${t('Минимальная сумма')} ${asMoney(minAmount, preparedCurrency, 2)}`
        },
        test: (value, context) => {
          const { currency: preparedCurrency } = recomposeCurrency(context.parent.currency)
          const minValue = MIN_AMOUNTS[preparedCurrency] ?? MIN_AMOUNT
          const minAmount = exchangeFromBaseCurrency(minValue, preparedCurrency, currencyRates)

          return context.parent.currencyAmount >= minAmount
        },
      })
      .test({
        name: 'max',
        params: { currency: lastCurrency },
        message: ({ currency }) => {
          const { currency: preparedCurrency } = recomposeCurrency(currency)
          const maxAmount = exchangeFromBaseCurrency(MAX_AMOUNT, preparedCurrency, currencyRates)

          return `${t('Максимальная сумма')} ${asMoney(maxAmount, preparedCurrency, 2)}`
        },
        test: (value, context) => {
          const { currency: preparedCurrency } = recomposeCurrency(context.parent.currency)
          const maxAmount = exchangeFromBaseCurrency(MAX_AMOUNT, preparedCurrency, currencyRates)

          return context.parent.currencyAmount <= maxAmount
        },
      }),
    currency: yup
      .mixed()
      .oneOf([
        CURRENCY_BASE,
        composeCurrency(CURRENCY_USDT, BLOCKCHAIN_TRX),
        composeCurrency(CURRENCY_TRX, BLOCKCHAIN_TRX),

        composeCurrency(CURRENCY_USDT, BLOCKCHAIN_BSC),
        composeCurrency(CURRENCY_BUSD, BLOCKCHAIN_BSC),
        composeCurrency(CURRENCY_BNB, BLOCKCHAIN_BSC),

        composeCurrency(CURRENCY_USDT, BLOCKCHAIN_ETH),
        composeCurrency(CURRENCY_USDC, BLOCKCHAIN_ETH),
        composeCurrency(CURRENCY_DAI, BLOCKCHAIN_ETH),
        composeCurrency(CURRENCY_ETH, BLOCKCHAIN_ETH),
      ])
      .required(t('Обязательно для заполнения')),
    blockchainReceipt: yup.mixed().oneOf([BLOCKCHAIN_BSC]).required(t('Обязательно для заполнения')),
    // account: yup
    //   .string()
    //   .matches(/[a-zA-Z0-9]{32}/, { message: t('Неправильный формат') })
    //   .required(t('Обязательно для заполнения')),
  })

  const { form, getFieldError, setFormValues, setFormErrors } = useFormWrapper(validationSchema, {
    mode: 'all',
    defaultValues: {
      ...stepData,
      currency: composeCurrency(stepData.currency, stepData.blockchain),
    },
  })

  const { currency, currencyAmount } = form.getValues()
  const isUserGuest = isGuest(user)
  const isPaymentInternal = currency === CURRENCY_BASE
  const balance = isUserGuest ? 0 : getBalance(user)

  const { currency: preparedCurrency } = recomposeCurrency(currency)

  const { changedField, additional, calculateAmount, calculateCurrencyAmount, calculateField } = useCalculator(
    token,
    (amount, currency) => setFormValues({ currencyAmount: roundCurrencyAmount(amount, currency) }, {}, true),
    (amount) => setFormValues({ amount: roundCurrencyAmount(amount, currency) }, {}, true),
    300,
  )

  useMount(() => {
    form.watch()
    getRates().then((data) => setCurrencyRates(data))
    getToken(token).then(({ rate, discount }) => {
      setRate(rate)
      setRateDiscount(discount)
    })
  })

  // initial calculator values
  useEffect(() => {
    if (initial) {
      return
    }

    if (stepData.amount) {
      calculateAmount(stepData.amount, stepData.currency, stepData.blockchain)
      setInitial(true)
    } else {
      if (rate > 0) {
        const amount = Math.ceil(PURCHASE_MIN_AMOUNT / rate)

        setFormValues({ amount })
        calculateAmount(amount, stepData.currency, stepData.blockchain)
        setInitial(true)
      }
    }
  }, [JSON.stringify(stepData), initial, rate])

  useEffect(() => {
    if (Object.keys(stepData.errors).length > 0) {
      setFormErrors(stepData.errors)
      updateStep(1, { errors: {} })
    }
  }, [JSON.stringify(stepData)])

  const handleSetMaxAmount = (ev) => {
    ev.preventDefault()

    const amount = roundDown(balance)

    setFormValues({ currencyAmount: amount }, {}, true)
    calculateCurrencyAmount(amount, currency)
  }

  const handleChangeCurrency = (opt) => {
    const selectedCurrency = opt.value
    const { currency, blockchain } = recomposeCurrency(selectedCurrency)

    setLastCurrency(selectedCurrency)
    setFormValues({ blockchain })

    calculateField('currency', currency, { blockchain })
  }

  const handleChangeAmount = (ev) => {
    const { currency: preparedCurrency, blockchain } = recomposeCurrency(currency)

    calculateAmount(ev.target.value, preparedCurrency, blockchain)
  }

  const handleChangeCurrencyAmount = (ev) => {
    const { currency: preparedCurrency, blockchain } = recomposeCurrency(currency)

    calculateCurrencyAmount(ev.target.value, preparedCurrency, blockchain)
  }

  const handleSubmit = (values) => {
    if (!form.formState.isValid) {
      return
    }

    const { currency: preparedCurrency, blockchain } = recomposeCurrency(currency)

    updateStep(1, {
      ...values,
      currency: preparedCurrency,
      blockchain,
      errors: {},
    })
    setActiveStep(2)
  }

  const currencyOptions = [
    {
      label: CURRENCY_USDT,
      title: CURRENCY_USDT,
      value: composeCurrency(CURRENCY_USDT, BLOCKCHAIN_TRX),
      description: getCurrencyDescription(CURRENCY_USDT, BLOCKCHAIN_TRX),
      icon: getCurrencyIcon(CURRENCY_USDT, BLOCKCHAIN_TRX),
      disabled: false,
    },
    {
      label: CURRENCY_TRX,
      value: composeCurrency(CURRENCY_TRX, BLOCKCHAIN_TRX),
      description: getCurrencyDescription(CURRENCY_TRX, BLOCKCHAIN_TRX),
      icon: getCurrencyIcon(CURRENCY_TRX, BLOCKCHAIN_TRX),
      disabled: false,
    },
    {
      label: CURRENCY_USDT,
      value: composeCurrency(CURRENCY_USDT, BLOCKCHAIN_BSC),
      description: getCurrencyDescription(CURRENCY_USDT, BLOCKCHAIN_BSC),
      icon: getCurrencyIcon(CURRENCY_USDT, BLOCKCHAIN_BSC),
      disabled: false,
    },
    {
      label: CURRENCY_BUSD,
      value: composeCurrency(CURRENCY_BUSD, BLOCKCHAIN_BSC),
      description: getCurrencyDescription(CURRENCY_BUSD, BLOCKCHAIN_BSC),
      icon: getCurrencyIcon(CURRENCY_BUSD, BLOCKCHAIN_BSC),
      disabled: false,
    },
    {
      label: CURRENCY_BNB,
      value: composeCurrency(CURRENCY_BNB, BLOCKCHAIN_BSC),
      description: getCurrencyDescription(CURRENCY_BNB, BLOCKCHAIN_BSC),
      icon: getCurrencyIcon(CURRENCY_BNB, BLOCKCHAIN_BSC),
      disabled: false,
    },
    {
      label: CURRENCY_USDT,
      value: composeCurrency(CURRENCY_USDT, BLOCKCHAIN_ETH),
      description: getCurrencyDescription(CURRENCY_USDT, BLOCKCHAIN_ETH),
      icon: getCurrencyIcon(CURRENCY_USDT, BLOCKCHAIN_ETH),
      disabled: false,
    },
    {
      label: CURRENCY_USDC,
      value: composeCurrency(CURRENCY_USDC, BLOCKCHAIN_ETH),
      description: getCurrencyDescription(CURRENCY_USDC, BLOCKCHAIN_ETH),
      icon: getCurrencyIcon(CURRENCY_USDC, BLOCKCHAIN_ETH),
      disabled: false,
    },
    {
      label: CURRENCY_DAI,
      value: composeCurrency(CURRENCY_DAI, BLOCKCHAIN_ETH),
      description: getCurrencyDescription(CURRENCY_DAI, BLOCKCHAIN_ETH),
      icon: getCurrencyIcon(CURRENCY_DAI, BLOCKCHAIN_ETH),
      disabled: false,
    },
    {
      label: CURRENCY_ETH,
      value: composeCurrency(CURRENCY_ETH, BLOCKCHAIN_ETH),
      description: getCurrencyDescription(CURRENCY_ETH, BLOCKCHAIN_ETH),
      icon: getCurrencyIcon(CURRENCY_ETH, BLOCKCHAIN_ETH),
      disabled: false,
    },
  ]

  if (!isUserGuest) {
    currencyOptions.push({
      label: CURRENCY_USCR_NAME,
      value: CURRENCY_BASE,
      description: getCurrencyDescription(CURRENCY_BASE),
      icon: getCurrencyIcon(CURRENCY_BASE),
    })
  }

  const blockchainReceiptOptions = [
    {
      label: getBlockchainLabel(BLOCKCHAIN_BSC),
      value: BLOCKCHAIN_BSC,
      icon: getBlockchainIcon(BLOCKCHAIN_BSC),
      disabled: false,
    },
    {
      label: getBlockchainLabel(BLOCKCHAIN_ETH),
      value: BLOCKCHAIN_ETH,
      icon: getBlockchainIcon(BLOCKCHAIN_ETH),
      disabled: true,
    },
    {
      label: getBlockchainLabel(BLOCKCHAIN_TRX),
      value: BLOCKCHAIN_TRX,
      icon: getBlockchainIcon(BLOCKCHAIN_TRX),
      disabled: true,
    },
  ]

  return (
    <form onSubmit={form.handleSubmit(handleSubmit)}>
      <div className="buy-token--form">
        <div className="form-row">
          <div className="form-col">
            <FormField error={getFieldError('currency')}>
              <label htmlFor="currency" className="control-label">
                {t('Способ оплаты')}
              </label>

              <Dropdown
                control={form.control}
                name={'currency'}
                options={currencyOptions}
                onChange={handleChangeCurrency}
              />
            </FormField>
          </div>
          <div className="form-col">
            <FormField error={getFieldError('blockchainReceipt')}>
              <label htmlFor="blockchainReceipt" className="control-label">
                {t('Блокчейн получения')}
              </label>

              <Dropdown control={form.control} name={'blockchainReceipt'} options={blockchainReceiptOptions} />
            </FormField>
          </div>
        </div>
        <div className="form-row">
          <div className="form-col">
            <FormField error={getFieldError('amount')}>
              <label htmlFor="amount" className="control-label">
                {t('Количество токенов')}
              </label>
              <div className={`form-field `}>
                <Input name={'amount'} register={form.register} onChange={(ev) => handleChangeAmount(ev)} />
                <div className="form-field--option">{token}</div>
              </div>
            </FormField>
          </div>
          <div className="form-col">
            <FormField error={getFieldError('currencyAmount')}>
              <label htmlFor="currencyAmount" className="control-label">
                {t('К оплате')}
              </label>
              <div className={`form-field `}>
                <Input
                  name={'currencyAmount'}
                  register={form.register}
                  onChange={(ev) => handleChangeCurrencyAmount(ev)}
                />
                <div className="form-field--option">{preparedCurrency}</div>
              </div>
              {isPaymentInternal && (
                <div className="form-group--controls">
                  <a href="#" onClick={(ev) => handleSetMaxAmount(ev)}>
                    max: {asMoney(balance, currency)}
                  </a>
                </div>
              )}
            </FormField>
          </div>
        </div>

        {/*        <FormField error={getFieldError('account')}>
          <Input
            label={t('Кошелек Relictum Node')}
            name={'account'}
            placeholder={t('Введите кошелек из 32 символов')}
            register={form.register}
          />
        </FormField> */}
      </div>

      {/*      <div className="buy-token--node">
        <div className="node-reminder">
          <div className="node-reminder--content">
            <div className="node-reminder--icon" />
            <div className="node-reminder--text">
              <div className="node-reminder--title">{t('Еще нет Relictum Node?')}</div>
              <div className="node-reminder--description">{t('Установите прямо сейчас!')}</div>
            </div>
          </div>
          <div className="node-reminder--buttons">
            <a href="https://relictum.pro/downloads" className="btn" target="_blank">
              {t('Скачать')}
            </a>
          </div>
        </div>
      </div> */}

      <div className="buy-token--summary">
        <div className="buy-token-summary">
          <div className="buy-token-summary--title">{t('Важная информация')}</div>

          <div className="buy-token-summary--list">
            <ul className="buy-token-summary-list">
              <li className="buy-token-summary-list--item">
                <div className="buy-token-summary-list--item-title">{t('Минимальная сумма')}</div>
                <div className="buy-token-summary-list--item-value">
                  {asMoney(
                    exchangeFromBaseCurrency(MIN_AMOUNTS[preparedCurrency], preparedCurrency, currencyRates),
                    preparedCurrency,
                    2,
                  )}
                </div>
              </li>
              <li className="buy-token-summary-list--item">
                <div className="buy-token-summary-list--item-title">{t('Максимальная сумма')}</div>
                <div className="buy-token-summary-list--item-value">
                  {asMoney(exchangeFromBaseCurrency(MAX_AMOUNT, preparedCurrency, currencyRates), preparedCurrency, 2)}
                </div>
              </li>
              <li className="buy-token-summary-list--item">
                <div className="buy-token-summary-list--item-title">{t('Комиссия')}</div>
                <div className="buy-token-summary-list--item-value">
                  {asMoney(fees.data[currency], preparedCurrency)}
                </div>
              </li>
            </ul>
          </div>

          {changedField === 'amount' && (
            <>
              {rateDiscount.isActive && (
                <div className="buy-token-summary--total" style={{ fontSize: '1.8rem', marginBottom: '.5rem' }}>
                  <div className="buy-token-summary--total-title">{t('Итого', { percent: rateDiscount.percent })}</div>
                  <div className="buy-token-summary--total-value" style={{ fontWeight: 'normal' }}>
                    {asMoney(additional.defaultAmount.value, additional.defaultAmount.currency)}
                  </div>
                </div>
              )}
              {rateDiscount.isActive && (
                <div className="buy-token-summary--total" style={{ fontSize: '1.8rem', marginBottom: '.5rem' }}>
                  <div className="buy-token-summary--total-title">
                    {t('Скидка {{percent}}%', { percent: rateDiscount.percent })}
                  </div>
                  <div className="buy-token-summary--total-value" style={{ fontWeight: 'normal' }}>
                    {asMoney(additional.discount.value, additional.discount.currency)}
                  </div>
                </div>
              )}

              <div className="buy-token-summary--total">
                <div className="buy-token-summary--total-title">{t('Итого к оплате')}</div>
                <div className="buy-token-summary--total-value">{asMoney(currencyAmount, preparedCurrency)}</div>
              </div>
            </>
          )}

          {changedField === 'currencyAmount' && (
            <>
              {rateDiscount.isActive && (
                <div className="buy-token-summary--total" style={{ fontSize: '1.8rem', marginBottom: '.5rem' }}>
                  <div className="buy-token-summary--total-title">
                    {t('Скидка {{percent}}%', { percent: rateDiscount.percent })}
                  </div>
                  <div className="buy-token-summary--total-value" style={{ fontWeight: 'normal' }}>
                    {asMoney(additional.discount.value, additional.discount.currency)}
                  </div>
                </div>
              )}

              <div className="buy-token-summary--total">
                <div className="buy-token-summary--total-title">{t('Итого к оплате')}</div>
                <div className="buy-token-summary--total-value">
                  {asMoney(additional.defaultAmount.value, additional.defaultAmount.currency)}
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <div className="buy-token--buttons">
        <button type="submit" className="btn btn-primary" disabled={!form.formState.isValid}>
          {t('Далее')}
        </button>
      </div>
    </form>
  )
}

export default Payment
